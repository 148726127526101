var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-row',[_c('v-col',{staticClass:"ma-1"},[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Paramètres généraux")]),_c('v-tab',[_vm._v(" Liste des requetes ")]),_c('v-tab',[_vm._v(" Modules de recherche ")]),_c('v-tab',[_vm._v(" Actions ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Select',{attrs:{"item":{name: 'Quel type de Chart ?',value: 'type',liste: _vm.listTypeCharts,
              defaultprops:{ show: true}},"change":(item,value) => { _vm.displays.params.generalparams.type = value['value']; },"valField":_vm.displays.params.generalparams.type}})],1),_c('v-tab-item',[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":_vm.addListe}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-expansion-panels',{staticClass:"pa-3",attrs:{"variant":"popout"},model:{value:(_vm.listQuerysAccordion),callback:function ($$v) {_vm.listQuerysAccordion=$$v},expression:"listQuerysAccordion"}},_vm._l((_vm.displays.params.listquerys),function(query,i){return _c('v-expansion-panel',{directives:[{name:"show",rawName:"v-show",value:(query.key!='default'),expression:"query.key!='default'"}],key:'lf'+i},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [(query.key!='default')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeListe(i)}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(query.default)+" ")]),_c('v-expansion-panel-content',{staticClass:"grey lighten-3"},[_c('div',[_c('TextField',{attrs:{"item":{name:'clé',value:'key',defaultprops:{show:true}},"keyup":(item,value) => {query.key = value },"valField":query.key}}),_c('Select',{attrs:{"item":{name: 'table ciblée',value: 'table',liste: _vm.listTables,
                    defaultprops:{ show: true,clearable:true } },"change":(item,value) => { query.table = value['value'] || ''; },"valField":query.table}})],1),(query.table && query.table!='')?_c('v-tabs',{model:{value:(_vm.tabsFiche),callback:function ($$v) {_vm.tabsFiche=$$v},expression:"tabsFiche"}},[_c('v-tab',[_vm._v(" Filtre ")]),_c('v-tab',[_vm._v(" Champs ")]),_c('v-tabs-items',{model:{value:(_vm.tabsFiche),callback:function ($$v) {_vm.tabsFiche=$$v},expression:"tabsFiche"}},[_c('v-tab-item',[_c('Filtre',{attrs:{"page":_vm.page,"filtre":query.filtre,"table":query.table}})],1),_c('v-tab-item',[_c('Fields',{attrs:{"page":_vm.page,"champs":query.champs,"filter":query.filtre,"table":query.table}})],1)],1)],1):_vm._e()],1)],1)}),1)],1),_c('v-tab-item',[_c('div',{staticClass:"text-h6"},[_vm._v(" Si le ou les modules sélectionnés se trouvent sur la même page, ils interagieront avec le tableau ")]),_c('SimpleTable',{attrs:{"Headers":[
                { text: 'ID', value: 'id' },
                { text: 'champs', value: 'champs' },
              ],"datas":_vm.dataTableSearch,"selected":_vm.displays.params.searchmodule,"keyRow":"id"},on:{"getselected":_vm.getselected}}),_c('div',{staticClass:"text-h6"},[_vm._v(" champs selectionnés pour la recherche globale ")]),_c('Autocomplete',{attrs:{"item":{
                name: 'Champs ciblés pour recherche globale',
                value: 'globalsearch',
                defaultprops: { show: true, multiple: true },
                liste: _vm.listFields,
              },"valField":_vm.displays.params.globalsearch,"change":_vm.changeGlobalSearch}})],1),_c('v-tab-item',[_c('listActions',{key:_vm.reload,attrs:{"page":_vm.page,"module":_vm.module}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }