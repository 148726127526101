<template>


    <div> 

        <v-dialog
        v-model="dialPhoto"
        :fullscreen="($vuetify.breakpoint.xs) ? true : false"
        width="70%"
        :retain-focus="false"
        @click:outside="closeDial"
        >
        <v-card>
        <v-card-text>
            <component 
                :mod="mod"
                v-bind:is="'bibli-component'" 
                :idmodule="modulePhoto" 
                :modal="modal"
                :customparams="{allowselect:true}"
                :page="page"
                :hook="hook"
                />
        </v-card-text>
        </v-card>
    </v-dialog>


        {{ item.name }}
            <editor
            v-model="model"
            api-key="bgo8w0j6apq7w4byhpcdxp25pr4pi7dmpusju4qkiqqxmyn1"
            :init="config"
            v-on:Onkeyup="keyup(item,model,false)"
            v-on:Onchange="keyup(item,model,false)"
            :key="key"
            />      

    </div> 
    </template>  
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
    import Editor from '@tinymce/tinymce-vue';
    import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
        export default {
            name : 'TextEditor',
    
            mixins : [mixinFields,mixinConditionsFields],
 
            components : {
                'editor': Editor
            },

            data: function() {
                return { 

                    config : {
                        language: 'fr_FR',
                        height: 500,
                        menubar: true,
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        ['undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent','']
                        },
                        firstload : true,
                        buttons : [],
                        dialPhoto : false,
                        modulePhoto : false,
                        key : 0,
                }
            },

            watch : {

                store() {
                    if(this.firstload) this.key++;
                },

                '$store.getters.getPicture': {
                    // permet de rafraichir l'encart de la photo après selection
                    handler(val) {

                        if (val && this.formThisField) {
                            
                            if(val.image_url) {
                            getTinymce().activeEditor.insertContent('<img src="'+val.image_url+'" width=30%>');
                            }
                            this.formThisField = false;
                            this.$store.dispatch('removePicture')
                            this.closeDial()
                        }

                    },
                    deep: true

                },

            },

            computed : {
                store() {
                    return this.mod;

                },
                closeChildModal() {
                    return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
                }
            },
    
            methods : {

                addbutton() {
                     
                    this.config.setup = (editor) => {
                        
                        this.item.otherparams.menueditor.forEach((elem,i) => {
                            editor.ui.registry.addMenuButton('button'+i, {
                            text: elem.namelist,
                            icon : elem.icon,
                            fetch: (callback) => {
                                var ee = [];
                                elem.list.forEach((link)=> {
                                    var item = {
                                        type: link.type,
                                        text : link.text,
                                        icon : link.icon,
                                        onAction:  () => {
                                            if(link.link) {
                                                this.modulePhoto = link.link
                                                this.dialPhoto = true
                                                this.formThisField = true
                                            } else {
                                                editor.insertContent(link.value);
                                            }
                                        
                                        }
                                    }
                                    ee.push(item);
                                })
                                callback(ee);
                            }
                        });
                        this.config.toolbar[1] = this.config.toolbar[1]+' button'+i;
                        })

                    }

                    if(this.firstload) this.key++;
                },

                closeDial() {
                    this.dialPhoto = false
                    this.formThisField = false
                },


            },

            created() {
                this.addbutton()
                this.firstload = false
            }
    
        }
    
    </script>