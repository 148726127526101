import interact from 'interactjs'

export default {

    data: function() {

        return {
            selectedPeriod: this.$route.query.period,
            defaultWidthTd: 0,
            initialDiv: {},
            checkForSameDay: {},
            ondragDiv: false,
            PlanningParams: {
                AMHourStart: '08',
                AMMinStart: '00',
                AMHourEnd: '12',
                AMMinEnd: '00',
                PMHourStart: '13',
                PMMinStart: '00',
                PMHourEnd: '17',
                PMMinEnd: '00',
                displayAllDay: 0, // 0 : affiche tout, 1 affiche que les jours, 2 affiche que les horaires,
                heightTDday: 20,
                minHeightDivDay: 18,
                fontSizeDiv: 12,

            },

        }
    },


    computed: {

        firstDayOfMonth() {
            const dateToUse = this.$route.query.start ? this.moment(this.$route.query.start) : this.moment();
            if (!this.$route.query.start) this.$router.push({ query: {...this.$route.query, start: dateToUse.format('YYYY-MM-DD') } }).catch(() => {});
            return (this.selectedPeriod != 0) ? dateToUse.startOf('month').startOf('week').format('YYYY-MM-DD') : dateToUse.startOf('week').format('YYYY-MM-DD');
        },

        lastDayOfPeriod() {
            const dateToUse = this.$route.query.start ?
                this.moment(this.$route.query.start) :
                this.moment();
            let endOfLastMonth = dateToUse

            if (this.selectedPeriod != 0) endOfLastMonth = dateToUse.add(this.selectedPeriod - 1, 'months').endOf('month');


            return endOfLastMonth.clone().endOf('week').format('YYYY-MM-DD');
        },

        // Générer un tableau avec chaque jour entre startDate et endDate
        daysInPeriod() {
            const startMoment = this.moment(this.firstDayOfMonth);
            const endMoment = this.moment(this.lastDayOfPeriod);
            const days = [];

            // Générer tous les jours entre startDate et endDate
            while (startMoment <= endMoment) {
                days.push(startMoment.clone());
                startMoment.add(1, 'days'); // Ajouter 1 jour à la date actuelle
            }

            return days;
        },

        weeks() {
            const weeks = [];
            let currentWeek = [];
            let currentWeekStart = this.moment(this.firstDayOfMonth).startOf('week');
            let weekNumber = currentWeekStart.isoWeek(); // Numéro de la semaine (ISO)

            // Créer les semaines à partir des jours
            for (let i = 0; i < this.daysInPeriod.length; i++) {
                const day = this.daysInPeriod[i];
                currentWeek.push(day);

                // Si on a atteint la fin de la semaine, ajouter la semaine au tableau
                if (currentWeek.length === 7 || i === this.daysInPeriod.length - 1) {
                    weeks.push({ weekNumber, days: currentWeek });
                    currentWeek = [];
                    // Réinitialiser la semaine suivante
                    currentWeekStart = currentWeekStart.add(1, 'week');
                    weekNumber = currentWeekStart.isoWeek(); // Mettre à jour le numéro de semaine
                }
            }

            return weeks;
        },

        indexedEvents() {
            let indexed = {};

            this.listEvents.forEach(event => {
                const eventStart = this.moment(event.start);
                const eventEnd = this.moment(event.end);
                const diffDays = eventEnd.diff(eventStart, 'days');
                event.nbdays = diffDays === 0 ? 1 : diffDays;

                const dayKey = eventStart.format("YYYY-MM-DD"); // Clé par jour
                const lineKey = event.line; // Clé par ligne

                if (!indexed[dayKey]) {
                    indexed[dayKey] = {};
                }
                if (!indexed[dayKey][lineKey]) {
                    indexed[dayKey][lineKey] = [];
                }
                if (event.allday>0) {
                indexed[dayKey][lineKey].push(event);
                }
            });

            return indexed;
        },

        indexedHalfDayEvents() {
            let indexed = {};

            this.listEvents.forEach(event => {
                const eventStart = this.moment(event.start);
                const dayKey = eventStart.format("YYYY-MM-DD"); // Clé du jour
                const lineKey = event.line; // Clé de la ligne
                const typeKey = eventStart.hour() < 12 ? 'am' : 'pm'; // Clé matin/après-midi

                if (!indexed[dayKey]) {
                    indexed[dayKey] = {};
                }
                if (!indexed[dayKey][lineKey]) {
                    indexed[dayKey][lineKey] = { am: [], pm: [] };
                }

                if (!event.allday || event.nbdays==0) {
                    indexed[dayKey][lineKey][typeKey].push(event);
                }
            });

            return indexed;
        },

        indexedAllDayEvents() {
            let indexed = {};

            this.listEvents.forEach(event => {
                if (!event.allday) return; // Ne traiter que les événements "allday"

                const eventStart = this.moment(event.start);
                const eventEnd = this.moment(event.end);
                const lineKey = event.line;

                // Boucler sur tous les jours de l'événement pour bien indexer
                let currentDay = eventStart.clone();
                while (currentDay.isBefore(eventEnd, "day")) {
                    const dayKey = currentDay.format("YYYY-MM-DD");

                    if (!indexed[dayKey]) {
                        indexed[dayKey] = {};
                    }
                    if (!indexed[dayKey][lineKey]) {
                        indexed[dayKey][lineKey] = [];
                    }
                    if (event.allday==1) {
                        indexed[dayKey][lineKey].push(event);
                    }
                    
                    currentDay.add(1, "day"); // Passer au jour suivant
                }
            });

            return indexed;
        }

    },

    watch: {
        '$route.query.period' () {
            //permet de réinitialiser les interactions afin que interact reprenne les bon width et height des div. 
            //car si on modifie le width avec css ou js, interact ne ne le vois pas.
            this.refreshActions()
        },
    },

    methods: {

        isWeekend(day) {
            // Vérifie si le jour est un samedi (6) ou un dimanche (0)
            const dayOfWeek = day.isoWeekday(); // Utilise `isoWeekday` pour que lundi soit 1
            return dayOfWeek === 6 || dayOfWeek === 7;
        },


        prev() {
            let date = this.moment(this.$route.query.start)
            if (this.selectedPeriod != 0) {
                this.$router.push({ query: {...this.$route.query, start: date.subtract(1, "month").startOf('month').format('YYYY-MM-DD') } }).catch(() => {});
            } else {
                this.$router.push({ query: {...this.$route.query, start: date.subtract(1, "week").startOf('week').format('YYYY-MM-DD') } }).catch(() => {});
            }
        },
        next() {
            let date = this.moment(this.$route.query.start)
            if (this.selectedPeriod != 0) {
                this.$router.push({ query: {...this.$route.query, start: date.add(1, "month").startOf('month').format('YYYY-MM-DD') } }).catch(() => {});
            } else {
                this.$router.push({ query: {...this.$route.query, start: date.add(1, "week").startOf('week').format('YYYY-MM-DD') } }).catch(() => {});
            }
        },

        //permet de trouver tous les rdv qui débutent sur la journée en question
        /*
        getStartEventsListOneDay(day, line, allday) {
            let list = []

            list = this.listEvents.filter(
                (event) => {
                    //firstDayOfMonth peremt d'afficher les rdv qui débutent avant le premier jour, mais dont la fin se trouve sur le calendrier
                    //const firstDayOfMonth = this.moment(this.firstDayOfMonth)
                    const eventStart = this.moment(event.start);
                    const eventEnd = this.moment(event.end);
                    const diffDays = eventEnd.diff(eventStart, 'days');
                    event.nbdays = (diffDays == 0) ? 1 : diffDays;

                    return eventStart.isSame(day, "day") && event.line == line && event.allday == allday;
                }
            );
            return list;
        },
       

        getEventsListHalfDay(day, line, type) {
            return this.listEvents.filter(
                (event) => {
                    //firstDayOfMonth peremt d'afficher les rdv qui débutent avant le premier jour, mais dont la fin se trouve sur le calendrier
                    //const firstDayOfMonth = this.moment(this.firstDayOfMonth)
                    const eventStart = this.moment(event.start);
                    return eventStart.isSame(day, "day") && event.line == line && event.allday == false &&
                        ((eventStart.hour() < '12' && type == 'am') ||
                            (eventStart.hour() >= '12' && type == 'pm')
                        )
                }
            );
        },
        */
        generateDataAttributes(event) {
            // Créer un objet pour les data-attributes
            const dataAttributes = {};
            Object.keys(event).forEach(key => {
                // Convertir la clé en format 'data-key'
                const dataKey = `data-${key}`;
                if (!['encart', 'tooltip', 'actions', 'otherfields'].includes(key)) dataAttributes[dataKey] = event[key];
                if (key == 'otherfields') dataAttributes['otherfields'] = JSON.stringify(event['otherfields'])
            });
            return dataAttributes;
        },

        adjustDivWidths() {
            const tdElements = this.$el.querySelectorAll('.getwidth');

            this.defaultWidthTd = tdElements[3].offsetWidth;

            tdElements.forEach((td) => {

                const div = td.querySelectorAll('.planning-divday-content');
                const divhour = td.querySelectorAll('.planning-divhour-content');

                if (div.length) {
                    div.forEach((uniqdiv) => {

                        uniqdiv.style.width = `${this.defaultWidthTd*uniqdiv.dataset.day}px`; // Utilise la largeur du td
                        uniqdiv.style.height = `${this.PlanningParams.minHeightDivDay}px`,
                            uniqdiv.style.fontSize = `${this.PlanningParams.fontSizeDiv}px`,
                            uniqdiv.style.backgroundColor = (uniqdiv.dataset.fontcolor) ? uniqdiv.dataset.fontcolor : '#FFFFFF',
                            uniqdiv.style.borderColor = uniqdiv.dataset.bordercolor
                    })
                }
                if (divhour.length) {
                    divhour.forEach((uniqdiv) => {
                        uniqdiv.style.minHeight = (this.selectedPeriod == 0) ? `${4*this.PlanningParams.minHeightDivDay}px` : `${this.PlanningParams.minHeightDivDay}px`;
                        uniqdiv.style.fontSize = `${this.PlanningParams.fontSizeDiv}px`,
                            uniqdiv.style.backgroundColor = (uniqdiv.dataset.fontcolor) ? uniqdiv.dataset.fontcolor : '#FFFFFF',
                            uniqdiv.style.borderColor = uniqdiv.dataset.bordercolor
                    })
                }
            });
        },

        getAllEventsListOneDay(day, notselected, line) {
            //notselected : permet de supprimer l'event qu'on peut choisir afin de voir les autres events à part celui-ci
            return this.listEvents.filter(
                (event) => {
                    const eventStart = this.moment(event.start);
                    const eventEnd = this.moment(event.end);
                    return (
                        eventStart.isSameOrBefore(day, "day") && eventEnd.isAfter(day, "day") &&
                        notselected != event.id &&
                        event.allday == true &&
                        line == event.line
                    );
                }
            );
        },

        /*eslint-disable */
        getDivStyle(event, day, line, allday) {


            const eventStart = this.moment(event.start);
            //event.id permet de ne pas prendre en compte l'event qui va etre affiché
            //const NumberDiv = this.getAllEventsListOneDay(day, event.id, line)
            const NumberDiv = this.indexedAllDayEvents[day.format('YYYY-MM-DD')]?.[line]?.filter(e => e.id !== event.id) || [];
            let top = false
            let lastEvent = {}
            let checkIfFreePos = []
            let checkIfSameDay = []
            let highestTop = 0
            const numberDivBehind = NumberDiv.filter((eventBehind) => {
                const start = this.moment(eventBehind.start);
                const end = this.moment(eventBehind.end);
                return (
                    start.isBefore(eventStart, "day") && end.isAfter(eventStart, "day") &&
                    event.line == line
                )
            }).sort((a, b) => {
                // Convertissez les dates en objets moment pour comparer
                const dateA = this.moment(a.start);
                const dateB = this.moment(b.start);

                // Tri croissant (remplacez par `dateB - dateA` pour un tri décroissant)
                return dateA - dateB;
            });

            //on applique le top en fonction du nombre d'event sur la journée
            top = numberDivBehind.length;

            //si il ya des events derrière qui dépasse celui-ci ou qui se trouvent sur le meme jour de départ
            if (numberDivBehind.length) {
                //on prend le dernier event avec son top pour voir si l'event qui va etre affiché doit etre en dessous
                lastEvent = numberDivBehind[numberDivBehind.length - 1];
                // si par contre le top précédent est similaire à ce top, ce top doit changer
                if (lastEvent.top) top = lastEvent.top + 1

                //si par contre il y a une place de libre en dessous du dernier event, sur la journée de départ du rdv :
                //this.indexedAllDayEvents[this.moment(lastEvent.start).format('YYYY-MM-DD')]?.[line]?.filter(e => e.id !== lastEvent.id) || []
                checkIfFreePos = this.indexedAllDayEvents[this.moment(lastEvent.start).format('YYYY-MM-DD')]?.[line]?.filter(e => e.id !== lastEvent.id)?.filter(ev => {
                    let end = this.moment(ev.end)
                    return end.isSameOrBefore(eventStart)
                })
                if (checkIfFreePos.length) {
                    // on prend le top le plus haut :
                    highestTop = checkIfFreePos.reduce((max, ev) => {
                        return ev.top && ev.top > max ? ev.top : max;
                    }, 0);
                    top = highestTop
                }

            }
            //si ils commencent le meme jour, on va faire un order pour le top
            checkIfSameDay = this.indexedAllDayEvents[day.format('YYYY-MM-DD')]?.[line]?.filter(e => e.id !== event.id) || []?.filter(ev => {
                    let start = this.moment(ev.start)
                    return start.isSame(eventStart)
                })
                // on check la position du div
            if (checkIfSameDay.length) {

                if (!this.checkForSameDay[day.format('YYYYMMDD') + '-' + line]) {
                    this.checkForSameDay[day.format('YYYYMMDD') + '-' + line] = {}
                    checkIfSameDay.forEach((ev) => {
                        this.checkForSameDay[day.format('YYYYMMDD') + '-' + line][ev.id] = false
                    })
                    this.checkForSameDay[day.format('YYYYMMDD') + '-' + line][event.id] = top
                } else {
                    top = Math.max(...Object.values(this.checkForSameDay[day.format('YYYYMMDD') + '-' + line])) + 1
                    this.checkForSameDay[day.format('YYYYMMDD') + '-' + line][event.id] = top;
                    const values = Object.values(this.checkForSameDay[day.format('YYYYMMDD') + '-' + line]);
                    const zeroCount = values.filter(value => value === false).length
                    if (zeroCount === 0) delete this.checkForSameDay[day.format('YYYYMMDD') + '-' + line]
                }

            }

            event.top = top
            return {
                top: `${top * this.PlanningParams.heightTDday}px`,
            }

        },

        calculHeightTd(day, line) {
            //const list = this.getAllEventsListOneDay(day, false, line)
            const list = this.indexedAllDayEvents[day.format('YYYY-MM-DD')]?.[line] || []
            let nb = list.length
            nb = (nb == 0) ? 1 : nb;
            return {
                height: `${nb*this.PlanningParams.heightTDday}px`,

            };
        },

        dropZoneFunc(event) {
            //lieu ou le drop s'effectue
            const encart = event.target.dataset;
            //element qui est droppé (un div ou un encart)
            const elem = event.relatedTarget.dataset

            //si le drop concerne une tranche de date sur le planning
            if (event.relatedTarget.newDiv) {
                //lors de la fin du drag d'un td pour créer un nouvel event
                //si on drop pour créer un nouveau div, on check si la date de debut est avant la date de fin. sinon on retourne rien
                let deb = this.moment(elem.day)
                let fin = this.moment(encart.day)
                if (deb < fin) {
                    let start = elem.day
                    start = this.moment(start).format("YYYY-MM-DD HH:mm:ss")
                    let end = this.moment(fin)
                    end = end.add(1, "days").format("YYYY-MM-DD HH:mm:ss")
                    this.createNewDayEvent({ start: start, end: end, line: Number(encart.line) })
                }

            } else {
                //si c'est un div existant dans le planning qu'on drop :
                if (elem.typeelement == 'div') {
                    let newDate = this.moment(encart.day);

                    let div = this.listEvents.find(el => el.id == elem.id)

                    //si on drop un div horaire dans un td journée
                    if (encart.allday && !div.allday) {
                        event.relatedTarget.classList.remove('planning-divhour-content')
                        event.relatedTarget.classList.add('planning-divday-content')
                        event.relatedTarget.classList.add('isdivresizable');
                        div.start = newDate.format('YYYY-MM-DD 00:00:00')
                        div.end = newDate.clone().add(1, "days").format('YYYY-MM-DD 00:00:00')
                        div.nbdays = 1
                        div.line = Number(encart.line)
                        div.allday = true
                    }
                    //si on drop un div journée dans un div horaire
                    if (!encart.allday && div.allday) {
                        if (encart.demi == 'am') {

                            div.start = newDate.clone().hour(this.PlanningParams.AMHourStart).minutes(this.PlanningParams.AMMinStart).format('YYYY-MM-DD HH:mm:ss')
                            div.end = newDate.clone().hour(this.PlanningParams.AMHourEnd).minutes(this.PlanningParams.AMMinEnd).format('YYYY-MM-DD HH:mm:ss')
                        }
                        if (encart.demi == 'pm') {
                            div.start = newDate.clone().hour(this.PlanningParams.PMHourStart).minutes(this.PlanningParams.PMMinStart).format('YYYY-MM-DD HH:mm:ss')
                            div.end = newDate.clone().hour(this.PlanningParams.PMHourEnd).minutes(this.PlanningParams.PMMinEnd).format('YYYY-MM-DD HH:mm:ss')
                        }
                        event.relatedTarget.classList.remove('planning-divday-content')
                        event.relatedTarget.classList.remove('isdivresizable')
                        event.relatedTarget.classList.add('planning-divhour-content');

                        div.line = Number(encart.line)
                        div.allday = false
                    }
                    //si on drop de journée à journée
                    if (encart.allday && div.allday) {
                        div.start = newDate.format('YYYY-MM-DD HH:mm:ss')
                        div.end = newDate.clone().add(div.nbdays, "days").format('YYYY-MM-DD HH:mm:ss')
                        div.line = Number(encart.line)
                    }
                    //si on drop un div d'horaire à horaire
                    if (!encart.allday && !div.allday) {
                        let start = this.moment(div.start)
                        div.start = newDate.clone().hour(start.hour()).minute(start.minute()).second(start.second()).format('YYYY-MM-DD HH:mm:ss')
                        let end = this.moment(div.end)
                        div.end = start.clone().hour(end.hour()).minute(end.minute()).second(end.second()).format('YYYY-MM-DD HH:mm:ss')
                        div.line = Number(encart.line)
                    }
                    this.onDropEvent({ el: div, day: newDate.format('YYYY-MM-DD'), line: Number(encart.line), allday: encart.allday })
                }

            }

            event.target.classList.remove('drop-activated');
        },

        dragEnterFunc(event) {
            //event.target est l'élément sur lequel il passe dessus
            if (!event.relatedTarget.newDiv) event.target.classList.add('drop-activated');
            // permet de savoir si on dragg un td. le newDiv est crée dans l'interact('droppable-td').draggable
            if (event.relatedTarget.newDiv) {
                let numbertd = 0
                    //numbertd donne un nombre entier de td que le div va remplir, donc le nombre de jour total  
                    //je fais cette condition car sur le dragenter la taille est similaire que le dragleave, il faut que je fasse un math.floor
                    //pour que ca prenne la case inférieure.
                if (this.initialDiv.getLeft > this.initialDiv.update.left) {
                    numbertd = Math.floor((this.initialDiv.update.left - this.initialDiv.initialLeft) / this.defaultWidthTd)
                } else {
                    numbertd = Math.ceil((this.initialDiv.update.left - this.initialDiv.initialLeft) / this.defaultWidthTd)
                }

                this.initialDiv.nbdays = numbertd
                this.initialDiv.getLeft = this.initialDiv.update.left
                if (numbertd > -1) event.relatedTarget.newDiv.style.width = `${this.initialDiv.startWidth+numbertd*this.defaultWidthTd}px`;
            }
        },

        initializeInteract() {
            console.log('init')
            interact('.isdivresizable').resizable({
                edges: { left: true, right: true, bottom: false, top: false },
                listeners: {
                    start: (event) => {

                        //on récupère les jours qui vont etre updatés, afin de ne pas updater tous le planning:
                        // Enregistrer les dimensions initiales
                        //permet d'enregistrer le left dès le premier chargement (car en position absolute). afin que si on resize le left encore une fois, ca ne remet pas le left à 0
                        this.initialDiv.initialLeft = (!event.target.dataset.initialLeft) ? event.target.getBoundingClientRect().left : event.target.dataset.initialLeft
                        if ((!event.target.dataset.initialLeft)) event.target.dataset.initialLeft = this.initialDiv.initialLeft;
                        this.initialDiv.update = event.rect;
                        //on récupère comment l'user a fait son resize : par la droite ou la gauche :
                        /* eslint-disable */
                        const edges = Object.entries(event.interaction.prepared.edges).find(([key, value]) => value);
                        this.initialDiv.typeresize = edges[0];
                        console.log(event.rect)
                        this.initialDiv.startWidth = event.target.getBoundingClientRect().width;
                        this.initialDiv.startLeft = event.target.getBoundingClientRect().left;
                        this.resizeStart(event.target.dataset)
                        this.ondragDiv = true
                    },

                    move: (event) => {

                        //event.rect : permet de stocker la position du div sur le resize : top, left, right bottom, width.
                        // this.initialDiv.update = event.rect;
                        //numbertd donne un nombre entier de td que le div va remplir, donc le nombre de jour total     

                        const numbertd = Math.ceil((this.initialDiv.update.width - this.initialDiv.startWidth) / this.defaultWidthTd)
                        this.initialDiv.nbdays = numbertd
                        event.target.style.width = `${this.initialDiv.startWidth+(numbertd*this.defaultWidthTd)}px`;
                        console.log(this.initialDiv.startWidth, numbertd, this.defaultWidthTd)
                            //pour le left, vu que le div est en absolute, il faut rendre le left négatif
                        if (this.initialDiv.typeresize == 'left') event.target.style.left = `${(this.initialDiv.initialLeft-this.initialDiv.startLeft)-(numbertd*this.defaultWidthTd)}px`;
                        this.onResize(event.target.dataset)
                    },
                    end: (event) => {
                        this.ondragDiv = false
                        const nbdays = this.initialDiv.nbdays
                        let div = this.listEvents.find(el => el.id == event.target.dataset.id)

                        switch (this.initialDiv.typeresize) {
                            case 'right':
                                {
                                    // on va rajouter le nombre de jour adéquate dans le end                              
                                    const end = this.moment(div.end).add(nbdays, 'days');
                                    div.end = end.format("YYYY-MM-DD HH:mm:ss");
                                    div.nbdays = div.nbdays + nbdays
                                    break;
                                }
                            case 'left':
                                {
                                    const start = this.moment(div.start).subtract(nbdays, 'days');
                                    div.start = start.format("YYYY-MM-DD HH:mm:ss");
                                    div.nbdays = div.nbdays - nbdays
                                    break;
                                }
                            default:
                                {
                                    event.target.style.width = this.initialDiv.startWidth
                                    event.target.style.left = this.initialDiv.initialLeft
                                    break;
                                }
                        }

                        this.resizeStop(div, event.target.dataset)
                    }
                },
                modifiers: [
                    interact.modifiers.restrictSize({
                        //permet de ne pas partir sur un width negatif
                        min: { width: this.defaultWidthTd - 3, height: 20 }, // Largeur et hauteur minimum
                    }),
                ],

            })



            interact('.isdivdraggable').draggable({
                listeners: {
                    start: (event) => {
                        event.target.style.zIndex = 2
                        event.target.style.opacity = 0.5
                        this.initialDiv.x = 0
                        this.initialDiv.y = 0;
                        this.ondragDiv = true
                    },
                    move: (event) => {
                        this.initialDiv.x += event.dx
                        this.initialDiv.y += event.dy
                        event.target.style.transform =
                            `translate(${this.initialDiv.x}px, ${this.initialDiv.y}px)`
                    },
                    end: (event) => {
                        this.ondragDiv = false
                        event.target.style.zIndex = 2
                        event.target.style.opacity = 1
                            // au cas ou il droppe nullepart, ca remet le div à sa place :
                        event.target.style.transform =
                            `translate(0px, 0px)`
                    }
                }
            });

            interact('.iscoldraggable').draggable({
                listeners: {
                    start: (event) => {
                        //si on drag l'élément présent dans le DOM il apr systematiquement en dessous du div parent. donc il faut le cloner..
                        const computedStyle = window.getComputedStyle(event.target);
                        const rect = event.target.getBoundingClientRect();
                        this.initialHeightDiv = computedStyle.height
                        this.initialDiv.x = 0
                        this.initialDiv.y = 0
                            //clonage
                        const clone = event.target.cloneNode(true);
                        //récup de toutes les propriété del 'elem d'origine 
                        for (const property of computedStyle) {
                            clone.style[property] = computedStyle.getPropertyValue(property);
                        }
                        // position absolute pour dépasser les autres
                        clone.style.position = 'absolute';
                        clone.style.top = `${rect.top}px`
                        clone.style.left = `${rect.left}px`;
                        clone.style.width = `${rect.width}px`
                            //intégration dans le dom
                        document.body.appendChild(clone);
                        //stockage pour suppression lors du end
                        this.initialDiv.clone = clone
                            // enlevement de l'elem d'origine
                        event.target.style.display = 'none';

                    },
                    move: (event) => {
                        this.initialDiv.x += event.dx
                        this.initialDiv.y += event.dy
                        this.initialDiv.clone.style.transform =
                            `translate(${this.initialDiv.x}px, ${this.initialDiv.y}px)`
                    },
                    end: (event) => {

                        //l'element qui est droppé
                        const elem = event.target;
                        //si il est droppé dans un element droppable :
                        if (!event.relatedTarget) {
                            event.target.style.display = '';
                            document.body.removeChild(this.initialDiv.clone)
                            return;
                        }
                        //le lieu ou c'est droppé
                        const encart = event.relatedTarget.dataset
                            //suppression du clone
                        document.body.removeChild(this.initialDiv.clone)
                            //réaffichage de l'elem d'origine
                        event.target.style.display = '';

                        let newDate = this.moment(encart.day);
                        let start = '';
                        let end = '';
                        if (encart.allday != 'true') {
                            if (encart.demi == 'am') {
                                start = newDate.clone().hour(this.PlanningParams.AMHourStart).minutes(this.PlanningParams.AMMinStart).format('YYYY-MM-DD HH:mm:ss')
                                end = newDate.clone().hour(this.PlanningParams.AMHourEnd).minutes(this.PlanningParams.AMMinEnd).format('YYYY-MM-DD HH:mm:ss')
                            }
                            if (encart.demi == 'pm') {
                                start = newDate.clone().hour(this.PlanningParams.PMHourStart).minutes(this.PlanningParams.PMMinStart).format('YYYY-MM-DD HH:mm:ss')
                                end = newDate.clone().hour(this.PlanningParams.PMHourEnd).minutes(this.PlanningParams.PMMinEnd).format('YYYY-MM-DD HH:mm:ss')
                            }
                        } else {
                            start = newDate.clone().format('YYYY-MM-DD 00:00:00')
                            end = newDate.clone().add("1", 'days').format('YYYY-MM-DD 00:00:00')
                        }


                        this.onDropCol(elem, { start: start, end: end, line: encart.line, allday: encart.allday == 'true' })
                    }
                }
            });


            //permet d'effectuer le drop sur les case jour entier
            interact('.droppable-td').dropzone({

                ondrop: this.dropZoneFunc,
                ondragenter: this.dragEnterFunc,
                ondragleave: (event) => {
                    event.target.classList.remove('drop-activated');
                },
            })

            // permet d'effectuer le drop sur les matin ou apres-midi dans chaque jour.
            interact('.droppable-div').dropzone({

                    ondrop: this.dropZoneFunc,
                    ondragenter: this.dragEnterFunc,
                    ondragleave: (event) => {
                        event.target.classList.remove('drop-activated');
                    },
                })
                //permet d'effectuer le drop sur les tabulation de liste de fiches pour l'intégrer dans la liste.
            interact('.droppable-tab').dropzone({

                ondrop: (event) => {
                    event.target.style.backgroundColor = '';
                    this.onDropTab(event.relatedTarget, event.target)
                },
                ondragenter: (event) => {
                    event.target.setAttribute('style', 'border: 2px dashed blue !important');
                },
                ondragleave: (event) => {
                    event.target.setAttribute('style', '');
                },
            })

            //permet de créer un nouveau div en selectionnant plusieurs case
            interact('.droppable-td')
                .draggable({
                    listeners: {
                        // Déclenché au début du clic et du glissement
                        start: (event) => {

                            const newDiv = document.createElement('div');
                            newDiv.classList.add('planning-divday-content');
                            newDiv.classList.add('planning-divday-content-new');
                            newDiv.textContent = '--';
                            newDiv.style.width = `${this.defaultWidthTd}px`
                            event.target.appendChild(newDiv);
                            //je stocke l'élément pour le supprimer par la suite
                            event.target.newDiv = newDiv
                            this.initialDiv.startWidth = event.target.getBoundingClientRect().width;
                            this.initialDiv.initialLeft = event.target.getBoundingClientRect().left;

                        },
                        // Déclenché pendant le mouvement du glissement
                        move: (event) => {
                            this.initialDiv.update = event.rect;
                        },
                        // Déclenché à la fin du clic ou du glissement
                        end: (event) => {
                            if (event.target.newDiv) {
                                event.target.newDiv.remove(); // Supprime le div du DOM
                                delete event.target.newDiv; // Supprime la référence pour éviter les fuites de mémoire
                            }
                        }
                    }
                });


        },

        refreshActions() {
            interact('.isdivresizable').unset();
            interact('.isdivdroppable').unset();
            interact('.isdivdraggable').unset();
            interact('.iscoldraggable').unset();
            interact('.droppable-td').unset();
            interact('.droppable-div').unset();
            interact('.droppable-tab').unset();
            setTimeout(() => {
                this.initializeInteract()
            }, 100)
        }


    },

    mounted() {
        this.adjustDivWidths();
        window.addEventListener('resize', this.adjustDivWidths);
        this.initializeInteract()
    },

    updated() {
        // Reajuster les divs à chaque mise à jour du DOM
        this.adjustDivWidths();


    },
    beforeDestroy() {
        // Nettoyer l'événement lors de la destruction du composant
        window.removeEventListener('resize', this.adjustDivWidths);

    },

}