import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify';
import store from './store/store'
import globals from './services/globalFunctions'
import router from './services/customRouter.js'
import Toast from "vue-toastification";



import Mixin from "./mixins/Global";
Vue.mixin(Mixin)
    // Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import './assets/globalstyle.css';

Vue.use(globals)

const options = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};


Vue.use(Toast, options);



Vue.component('main-component', require('./components/main/templates/mainTemplate.vue').default);

// template formulaire : n'affiche que la balise form
Vue.component('form-template', require('./components/main/templates/formTemplate-trash.vue').default);
// composant principal : permet de structurer la page
Vue.component('page-template', require('./components/main/templates/page/pageTemplate-trash.vue').default);



// composant qui permet de créer une modal par page
Vue.component('modal-template', require('./components/main/templates/modalTemplate.vue').default);

// les modules
Vue.component('table-component', require('./components/modules/table/TableModule.vue').default);
Vue.component('search-component', require('./components/modules/SearchModule.vue').default);
Vue.component('form-component', require('./components/modules/FormModule.vue').default);
Vue.component('calendar-component', require('./components/modules/calendar/CalendarModule.vue').default);
Vue.component('tabs-component', require('./components/modules/TabsModule.vue').default);
Vue.component('pdf-component', require('./components/modules/PdfModule.vue').default);
Vue.component('bibli-component', require('./components/modules/BibliothequeModule.vue').default);
Vue.component('sendemail-component', require('./components/modules/SendemailModule.vue').default);
Vue.component('frontdatas-component', require('./components/modules/FrontdatasModule.vue').default);
Vue.component('ged-component', require('./components/modules/GedModule.vue').default);
Vue.component('ventilation-component', require('./components/modules/VentilationModule.vue').default);
Vue.component('planning-component', require('./components/modules/planning/PlanningModule.vue').default);
Vue.component('codebarre-component', require('./components/modules/codebarreModule.vue').default);
Vue.component('echarts-component', require('./components/modules/EchartsModule.vue').default);

// pour le super user, permet d'edition des modules
Vue.component('edit-module', require('./components/engine/editModule/RoutingEdit.vue').default);
Vue.component('edit-action', require('./components/engine/editAction/editAction.vue').default);
Vue.component('edit-field', require('./components/engine/editField/Field.vue').default);
Vue.component('edit-variant', require('./components/engine/editField/editVariant.vue').default);
Vue.component('edit-tabulation', require('./components/engine/editModule/Tabulations/editTabulations.vue').default);
Vue.component('edit-variantpage', require('./components/main/templates/page/Edit/editVariant.vue').default);
Vue.component('edit-variantmodule', require('./components/engine/editModule/editVariant.vue').default);
Vue.component('change-password', require('./components/engine/manageUser/changePassword.vue').default);
Vue.component('create-user', require('./components/engine/manageUser/createUser.vue').default);
Vue.component('manage-api', require('./components/engine/manageAPI/externalAPI.vue').default);

Vue.component('manage-bdd', require('./components/engine/manageBdd.vue').default);

Vue.component('not-allowed', require('./components/outils/notAllowed.vue').default);

Vue.component('routing-droits', require('./components/engine/Auth/routingDroits.vue').default);

Vue.config.productionTip = false


Vue.use(Vuetify);

const InitVuetify = new Vuetify()
new Vue({
    vuetify: InitVuetify,
    router,
    store,

    computed: {

        prefs() {
            return this.$store.getters.currentUser.preferences;
        }
    },
    watch: {

        prefs: {
            // permet de rafraichir quand la modal de la page se ferme
            handler(val) {
                this.$vuetify.theme.dark = val.darkmode;
            },
            deep: true,
        }
    },
    created() {


        if (this.$route.name == 'recover' || this.$route.name == "forgotp" || this.$route.name == "register" || this.$route.name == "dropbox") {
            //
        } else {

            this.$store.dispatch('getoverlay', true);
            store.dispatch('textOverlay', 'Connexion au serveur')
                // on va intérroger l'API, voir si le token est créé et si il est valide.
            this.$store.dispatch("setUser").then((resp) => {

                // si tout est ok, on charge toutes les pages
                store.dispatch('textOverlay', 'Chargement des pages')
                this.getAllPages().then(() => {
                    this.$store.dispatch('SystemLoaded')
                    this.$store.dispatch('getoverlay', false);
                    //si première connection, on redirige vers la page favorite
                    if (localStorage.getItem("firstConnect")) {
                        localStorage.removeItem("firstConnect")
                        const defaultPage = resp.user.preferences.onopen

                        if (!defaultPage || typeof defaultPage == 'undefined') {
                            router.push('/').catch(() => {})
                        } else {
                            router.push({ name: defaultPage.name, params: defaultPage.params, query: defaultPage.querys }).catch(() => {})
                        }

                    }
                    this.$vuetify.theme.dark = this.$store.getters.currentUser.preferences.darkmode;
                })

            }, () => {
                console.log('erreur de chargement')
                    // si le token existe et qu'il n'est plus valide (ou qu'il n'existe pas), on repart sur login
                this.$store.dispatch('getoverlay', false);
                router.push('/login').catch(() => {})


            })

        }

    },

    render: h => h(App)
}).$mount('#app')