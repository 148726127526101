<template>
<div>

    <not-allowed v-if="allowed.success===false" :data="allowed"/>

    <!-- permet de modifier le nom et nom de clé du template actif-->
            <v-dialog v-model="dialogTemplate" width="800"
            @click:outside="closeDial"
            >

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                Modification du template 
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDial"><v-icon>mdi-close-thick</v-icon></v-btn>      
                </v-card-title>

                <v-card-text v-if="indexTemplate !==false">

                    <TextField 
                    :keyup="(item,value) => { template[indexTemplate].name = value }"
                    :valField="template[indexTemplate].name"
                    :item="{name:'Nom du template',value:'name',defaultprops:{show:true}}"
                    /> 
                    <TextField 
                    v-if="indexTemplate"
                    :keyup="(item,value) => { template[indexTemplate].key = value }"
                    :valField="template[indexTemplate].key"
                    :item="{name:'Clé',value:'key',defaultprops:{show:true}}"
                    />  

                    l'élément sera affiché si toutes les conditions sont remplis)

                    <groupConditions v-if="indexTemplate" :conditions="template[indexTemplate].conditions" @recepVal="(array) => { template[indexTemplate].conditions = array }" />

                </v-card-text>

            </v-card>
            </v-dialog>
    <v-container 
    v-if="selectedTemplate"
    :class="$store.getters.specificPage(page)['params']['class']"
    :fluid="$store.getters.specificPage(page)['params']['containerfluid']"
    :id="$store.getters.specificPage(page)['params']['containerid']"
    >    
   

        <div v-if="$store.getters.currentUser.type==3" class="text-center grey lighten-3 text-caption ma-4">Entreprise {{variantPage}} avec template <b>{{verifyActiv()}}</b>

            <v-switch
                v-if="($store.getters.currentUser.type==3)"
                absolute
                v-model="ModifTemplate"
                class="pa-0 ma-0 text-center float-left"
                hide-details
            ></v-switch> 
        </div>

         

                <!-- intégration des ventilation de cette page si il n'y a aucun crochet-->
                <ventilation-component :page="page" :modal="modal" :hook="hook" :form="form"/>

                <v-row v-if="ModifTemplate">    
                    <v-col class="col-12">
                        <List v-if="ModifTemplate && (!hook || hook=='search')" :page="page" :modal="modal" :template="template" @manageTemplate="manageTemplate" :selected="selectedTemplate" :form="form"/> 
                    </v-col>            
                    <v-col class="col-12 text-center">
                        <v-btn @click="savePage" class="blue" dark x-small><v-icon x-small>mdi-content-save</v-icon> {{selectedTemplate.name}}</v-btn>
                        <v-btn class="blue" dark x-small
                        @click="goTo({rootPage: page,name: 'editiondroit',type: 'modal',
                        // j'ai mis params:{id:item.module} juste pour permettre le rafraichissement du module... 
                        replace: { page:page, id: page, target:'page', root:'interface'},
                        })">                
                        <v-icon small>mdi-eye-outline</v-icon>
                        Droits
                        </v-btn> 
                    </v-col>
                </v-row>
            <v-row v-if="editTemplate!==false">
                <v-col class="text-center">
                    <v-alert outlined color="red">
                        Vous êtes en mode edition du template {{ selectedTemplate.name }}
                    <v-btn @click="savePage(); editTemplate = false" class="red" dark x-small><v-icon x-small>mdi-close-box</v-icon>Sauvegarder et sortir</v-btn>
                    </v-alert>
                </v-col>
            </v-row>

        <CPage 
        :pageTemplate="selectedTemplate.template" 
        :page="page" 
        :modal="modal" 
        :form="form" 
        :hook="hook"
        :customparams="customparams"
        :customquerys="customquerys"
        :mod="ModifTemplate"
        @recepVal="recupTemplate"
        />
        

    </v-container>
        <v-container>
        <transition name="custom-fade">
            <v-overlay color="white" v-if="!selectedTemplate && !findError" :value="true" absolute opacity="1">
                <v-row>
                    <v-col class="blue--text col-12">
                        Chargement de la page
                        <v-progress-linear
                        indeterminate
                        :width="5"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
        </v-overlay>
        </transition>

    </v-container>
</div>
</template>

<script>
import cond from "../../../../mixins/visuelConditions";
    export default {
        //page : id de la page : provenant soit de mainComponent, soit de formTemplate
        //modal : true ou false, si provient de la modal ou pas : permet de définir ou on va trouver les infos dans les modules
        //form : si provient de formTemplate : intègre le $refs.form ce qui va modifié le comportement de vuex si champs non remplis
        //et va donner l'info que le bouton d'enreg doit fonctionner
        //hook : 
        props : ['page','modal','form','hook','customparams','customquerys'],

        mixins : [cond],

        components : {

            List    : () => import('./listElem.vue'), 
            CPage    : () => import('../../../engine/outils/constructPage/Page.vue'),
            TextField : () => import('../../../fields/textfield.vue'),
            groupConditions : () => import('../../../engine/outils/constructConditions/Conditions.vue'),
        },

        data: function() {
            return {
            template : [],
            allowed : {message:'',success:''},
            //bool : permet de lancer le mode de construction du template
            ModifTemplate : false,
            //int (index) : permet de selectionner le template à modifier via la liste des templates ds params page
            editTemplate : false,
            selectedTemplate : false,
            indexTemplate : false,
            dialogTemplate : false,
            variantPage : 0,
            findError : false,
            urlParams : {params : {}, querys : {}},
            }
        },

        titleBrowser () {
        if(!this.hook) return this.replaceBy({text:this.$store.getters.specificPage(this.page)['displayname'],page:this.page})
        },

        watch : {
            //permet de sortir automatiquement du mode construction du template lors de la désactiv de modif template
            ModifTemplate(val) {
                if(!val) this.editTemplate = false
            },

        },

        methods : {

            getFromAPI() {

                this.API({type:'post', url:'page', params:{id : this.page, params : this.urlParams.params, querys : this.urlParams.querys }}).then((resp) => {


                        if(!resp.data.success) {
                            this.allowed = resp.data
                            this.findError = true;
                            return;
                        }

                        this.template = resp.data.page.displays.template;

                        this.$store.dispatch('insertModuleDatas',{page:this.page,modules:resp.data.modules})
                        
                        //this.$store.dispatch('constructTemplate/init_template',{page:this.page, variant : resp.data.page.displays.variant , templates : resp.data.page.displays.template, selected : 'default'})

                        //on prend directement celui par défaut 
                        this.selectedTemplate = this.template[0]

                        //check si une condition n'est pas remplis sur le template
                        this.verifyActiv()

                        this.variantPage = resp.data.page.displays.variant;                            
                        
                    })

            },


            recupTemplate(arr) {
                this.template.find(el => el.key == this.selectedTemplate.key).template = arr;
            },


            savePage() {
                this.API({type:'put', url:'savetemplate', params:{id:this.page, 
                                        template:this.template,
                                        variant:this.variantPage
                                        }})
            },

            manageTemplate(elems) {
                if(elems.type=='delete' && confirm('Vous allez supprimer cette ce template, continuer ?')) {
                    //si on supprime le template qui est entrain d'etre modifié, on revient directement sur le template par défaut
                    if(elems.index === this.editTemplate) {
                       this.editTemplate = false
                       this.verifyActiv()
                    }
                    this.template = this.template.filter((el,i) => i != elems.index);
                }
                if(elems.type=='build') {
                    this.editTemplate = elems.index
                    this.verifyActiv()
                }
                if(elems.type=='insert') {
                    this.template.push({name:'nouveau template',key:'newtemp',template:[],'conditions':[]})
                }
                if(elems.type=='update') {
                    
                    this.indexTemplate = elems.index;
                    this.dialogTemplate = true
                }                
            },
            closeDial() {
                this.dialogTemplate = false
                this.indexTemplate = false
            },

            verifyActiv() {
                var find = false;

                if(this.editTemplate!==false)  {

                    this.selectedTemplate = this.template[this.editTemplate];

                } else {
                    this.template.forEach((templ,i) => {
                        if(i!=0 && this.visuelConditions(templ.conditions).length && this.visuelConditions(templ.conditions).every(val => val === true)) {
                        find = templ;
                        }
                    })
                    if(find) {
                        this.selectedTemplate = find;
                    }  else {
                        this.selectedTemplate = this.template[0];
                    }
                }

                //j'ai fais un return pour afficher le nom dans le DOM, MAIS aussi, si je met la fonction dans le dom, ca va etre réactif à chaque 
                //changement de variables.... bizarre..
                return this.selectedTemplate.name
            }

        },

        mounted() {
            this.getFromAPI()       

        },
        created() {
            
           if(!this.hook) this.$store.dispatch('current',this.page)

                var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
                this.urlParams.params = (this.modal && modal.show) ? modal.params : this.$route.params   
                this.urlParams.querys = (this.modal && modal.show) ? modal.query : this.$route.query
                this.urlParams.params = (this.customparams) ? this.customparams : this.urlParams.params
                this.urlParams.querys = (this.customquerys) ? this.customquerys : this.urlParams.querys

        }

    }

</script>


<style>
/* Transition personnalisée */
.custom-fade-enter-active,
.custom-fade-leave-active {
  transition: opacity 0.5s ease-in-out; /* Durée de 500ms */
}

.custom-fade-enter,
.custom-fade-leave-to {
  opacity: 0;
}
</style>