<template>
<div justify="center" align="center">


    <v-dialog
        v-model="dialChoose"
        :fullscreen="($vuetify.breakpoint.xs) ? true : false"
        width="70%"
        :retain-focus="false"
        @click:outside="closeDial"
        >
        <v-card>
        <v-card-text>
            <component
                :key="reloadModule"  
                :mod="mod"
                v-bind:is="'bibli-component'" 
                :idmodule="item.otherparams.selectPhoto.module" 
                :modal="modal"
                :page="page"
                :customquerys="querysDial"
                :customparams="paramsDial"
                :hook="hook"
                />
        </v-card-text>
        </v-card>
    </v-dialog>

    {{ item.name }}
    <v-hover v-slot="{ hover }">
        <template>
            <v-card width="200" weight="180" >
                    <v-fade-transition>
                        <v-overlay
                        absolute
                        :value="hover"
                        :opacity="0.2"
                        >
                        <v-btn small icon class="primary ma-1" @click="click()"><v-icon>mdi-file-arrow-left-right-outline</v-icon></v-btn>
                        <v-btn small icon class="error ma-1" @click="remove()"><v-icon>mdi-file-remove-outline</v-icon></v-btn>
                        </v-overlay>
                    </v-fade-transition>
                    <img :src="img" width="200" weight="200">
            </v-card>
        </template>
    </v-hover>

</div>

    </template>
    
    
    
    <script>
    import mixinFields from '../../mixins/Fields'
    import mixinConditionsFields from '../../mixins/conditionsFields'
    import url from '../../services/baseURL';
        export default {
            name : 'TextField',
    
            mixins : [mixinFields,mixinConditionsFields],
    
            data: function() {
                return { 
                    overlay : false,
                    img : url.root+"images/placeholder.jpg",
                    dialChoose : false,
                    paramsDial : {},
                    querysDial : {},
                    reloadModule : 0,
                }
            },
    
            computed: {
                closeChildModal() {
                    return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
                }
            },    
            
            watch: {
                '$store.getters.getPicture': {
                    // permet de rafraichir l'encart de la photo après selection
                    handler(val) {

                        if (val && this.formThisField) {
                            let pic = val
                            if(pic.image_url) {
                            this.img = pic.image_url
                            this.keyup(this.item,pic.image_id)
                            }
                            this.formThisField = false
                            this.$store.dispatch('removePicture')
                            this.closeDial()
                        }

                    },
                    deep: true

                },
            },

            methods : {
                click() {
                    this.paramsDial  = {}
                    this.querysDial  = {}
                    this.item.otherparams.selectPhoto.params.forEach((p) => {
                        if (p.type && p.type == 'query') {
                            this.querysDial[p.key] = this.replaceBy({ text: p.value, page: this.page });
                        } else {
                            this.paramsDial[p.key] = this.replaceBy({ text: p.value, page: this.page });
                        }

                    })
                    this.paramsDial.allowselect = true
                    this.reloadModule++;
                    this.formThisField = true
                    this.dialChoose = true
                },

                closeDial() {
                    this.dialChoose = false
                    this.formThisField = false
                },

                remove() {
                    this.keyup(this.item,null)
                    this.img = url.root+"images/placeholder.jpg"
                }
            },

            mounted() {
                this.API({type:'post', url:'bibliotheque/getimage', params:{id:this.model}}).then((resp) => {
                        this.img = resp.data
                            
                    }) 
            }
    
        }
    
    </script>