<template>
  <v-card elevation="2" shaped class="ma-1">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Paramètres du module Formulaire </v-toolbar-title>
    </v-toolbar>
    <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
      <v-tab> Requete et Variables </v-tab>
      <v-tab> Config des lignes </v-tab>
      <v-tab> Affichage </v-tab>
      <v-tab> Actions </v-tab>
      <v-tab> Config des listes </v-tab>
      <v-tabs-items v-model="tab" class="pl-4">

        <v-tab-item>
          Permet de lister et paramétrer les events présents dans le planning. vous pouvez récupérer les couleurs et les intitulés
                <v-expansion-panels variant="popout" v-model="accordionQuery">
                  
                  <v-expansion-panel>
                  <v-expansion-panel-header>filtre</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Filtre :page="page" :filtre="displays.params.listEvent.filtre" :table="module.table" />
                  </v-expansion-panel-content>                
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>champs</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Fields :page="page" :champs="displays.params.listEvent.champs" :filter="displays.params.listEvent.filtre" :table="module.table" />
                  </v-expansion-panel-content>                 
                </v-expansion-panel>


              </v-expansion-panels>
              <ul class="font-italic">
                <li>Valeur pour date de début : <strong>[[start]]</strong></li>
                <li>Valeur pour date de fin : <strong>[[end]]</strong></li>
                <li>Valeur pour le filtre "la journée", ou "créneau horaire" : <strong>[[allday]]</strong></li>
                <li>Valeur pour la l'endroit ou se trouve l'event : <strong>[[line]]</strong></li>
              </ul> 

              <ArrayTable 
                :item="{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:fieldsInModuleTable},{type:'select',key:'type',name:'type de donnée',ArrayValue:[{text:'nombre',value:'number'},{text:'texte',value:'text'},{text:'booleen',value:'bool'}]},{type:'',key:'value',name:'Valeur du GET',description:'(Vous pouvez insérer des variables d\'environnement)'}]},
                defaultprops:{show:true,tableDraggable:false,tableDel:false,hideFooter:true,hidesearch:true,tableAdd:false}}"
                :valField="displays.params.sendget"
                :change="(item,value) => { this.displays.params.sendget = value; } "
                />              

        </v-tab-item>

        <v-tab-item>
          <v-tabs v-model="listGrouplignes">
              <v-tab> Lignes </v-tab>
              <v-tab> Groupe </v-tab>
              <v-tabs-items v-model="listGrouplignes">
                <v-tab-item>
                          <Select
                            :item="{name: 'table ciblée',value: 'val',liste: listTables,
                            defaultprops:{ show: true}}"
                            :change="(item,value) => { displays.params.lines.table = value['value']; }"
                            :valField="displays.params.lines.table"
                            />
                          <v-tabs v-model="listlignes" v-if="displays.params.lines.table!=''">
                              <v-tab> Filtre </v-tab>
                              <v-tab> champs </v-tab>
                              <v-tabs-items v-model="listlignes">
                                <v-tab-item>
                                  <Filtre :page="page" :filtre="displays.params.lines.filtre" :table="displays.params.lines.table" />
                                </v-tab-item>
                                <v-tab-item>
                                  <ul>
                                    <li> pour l'intitulé de la ligne, mentionnez "name" en AS</li>
                                    <li> pour l'ID de la ligne, mentionnez "line" en AS</li>
                                    <li> pour l'ID d'appartenance à un groupe, mentionnez "group" en AS</li>
                                  </ul>
                                  <Fields :page="page" :champs="displays.params.lines.champs" :filter="displays.params.lines.filtre" :table="displays.params.lines.table" />
                                </v-tab-item>
                              </v-tabs-items>
                          </v-tabs>
                </v-tab-item>
                <v-tab-item>
                          <Select
                            :item="{name: 'table ciblée',value: 'val',liste: listTables,
                            defaultprops:{ show: true,clearable:true}}"
                            :change="(item,value) => { displays.params.grouplines.table = (value)?value['value']:''; }"
                            :valField="displays.params.grouplines.table"
                            />
                            {{ displays.params.grouplines.table }}
                          <v-tabs v-model="listlignes" v-if="displays.params.grouplines.table">
                              <v-tab> Filtre </v-tab>
                              <v-tab> champs </v-tab>
                              <v-tabs-items v-model="listlignes">
                                <v-tab-item>
                                  <Filtre :page="page" :filtre="displays.params.grouplines.filtre" :table="displays.params.grouplines.table" />
                                </v-tab-item>
                                <v-tab-item>
                                  <ul>
                                    <li> pour l'intitulé du groupe, mentionnez "name" en AS</li>
                                    <li> pour l'ID de la ligne, mentionnez "line" en AS</li>
                                    <li> pour l'ID d'appartenance à un groupe, mentionnez "group" en AS</li>
                                  </ul>
                                  <Fields :page="page" :champs="displays.params.grouplines.champs" :filter="displays.params.grouplines.filtre" :table="displays.params.grouplines.table" />
                                </v-tab-item>
                              </v-tabs-items>
                          </v-tabs>
                </v-tab-item>
              </v-tabs-items>
          </v-tabs>

        </v-tab-item>

        <!--
          //
          //
          AFFICHAGE ENCART ET TOOLTIPS
          //
          //
          -->
        <v-tab-item>


            <v-tabs v-model="aff">
              <v-tab> encart </v-tab>
              <v-tab> tooltip </v-tab>
              <v-tab> actions </v-tab>

              <v-tabs-items v-model="aff">
            <!--
            //
            ENCART 
            //
            -->
                  <v-tab-item>
                    <p>Vous pouvez inclure dans les styles et valeurs, la variable d'environnement [[line||'nom_du_as']]</p>
                    <v-btn fab small @click="addVariant('encart')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionEncart" class="pa-3">
              <v-expansion-panel v-for="(encart, i) in displays.params.display.encart" :key="i">
                <v-expansion-panel-header>
                  {{encart.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'encart')" v-if="encart.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">

                    <TextField 
                    :item="{name:'Couleur de bordure',value:'bordercolor',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.bordercolor" />
                    <TextField 
                    :item="{name:'Couleur de fond',value:'fontcolor',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.fontcolor" />
                    <TextField 
                    :item="{name:'Couleur du point',value:'dotcolor',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.dotcolor" />
                  <div v-if="encart.key!='default'">
                    <TextField 
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'encart')}"
                    :valField="encart.key" />
                    
                    Variable utilisée : [[line||mon_AS]]
                    <groupConditions :conditions="encart.conditions" @recepVal="(array) => { updateConditions(array,'encart',i) }" />

                  </div>
                  <div class="elevation-2">
                    <div class="text-center text-h6">Affichage</div>
                    <Cpage 
                    :pageTemplate="encart.construct" 
                    :page="page" 
                    :modal="false" 
                    :form="false" 
                    hook=""
                    :mod="true"
                    @recepVal="(array) => {encart.construct = array}"
                    />                    
                  </div>


                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>


                  </v-tab-item>
            <!--
            //
            TOOLTIP 
            //
            -->
                  <v-tab-item>
                    <p>Vous pouvez inclure dans les styles et valeurs, la variable d'environnement [[line||'nom_du_as']]</p>
                    <v-btn fab small @click="addVariant('tooltip')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionTooltip" class="pa-3">
              <v-expansion-panel v-for="(tooltip, i) in displays.params.display.tooltip" :key="i">
                <v-expansion-panel-header>
                  {{tooltip.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'tooltip')" v-if="tooltip.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">
                  <v-row no gutters>
                    <v-col>
                    <TextField 
                    :item="{name:'largeur minimum',value:'minwidth',format:'number',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.minwidth" />
                    </v-col>
                    <v-col>
                      <TextField 
                    :item="{name:'position tooltip',value:'position',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.position" />
                    </v-col>
                  </v-row>

                  <div v-if="tooltip.key!='default'">
                    <TextField 
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'tooltip')}"
                    :valField="tooltip.key" />
                    
                    Variable utilisée : [[line||mon_AS]]
                    <groupConditions :conditions="tooltip.conditions" @recepVal="(array) => { updateConditions(array,'tooltip',i) }" />

                  </div>
                  <Cpage 
                  :pageTemplate="tooltip.construct" 
                  :page="page" 
                  :modal="false" 
                  :form="false" 
                  hook=""
                  :mod="true"
                  @recepVal="(array) => {tooltip.construct = array}"
                  />
                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

                  </v-tab-item>
            <!--
            //
            condition d'action 
            //
            -->   
                  <v-tab-item>
                <v-btn fab small @click="addVariant('actions')"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="accordionAction" class="pa-3">
              <v-expansion-panel v-for="(action, i) in displays.params.display.actions" :key="i">
                <v-expansion-panel-header>
                  {{action.key}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeVariantEncart(i,'actions')" v-if="action.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template>                 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">

                  <div>
                    <TextField                  
                    v-if="action.key!='default'"
                    :item="{name:'nom du variant',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value,selectedKey) => {changeNameVariantEncart(item,value,selectedKey,i,'actions')}"
                    :valField="action.key" />

                    Variable utilisée : [[line||mon_AS]]
                    <groupConditions :conditions="action.conditions" @recepVal="(array) => { updateConditions(array,'actions',i) }" />


                  </div>

                  <Autocomplete :item="{
                  defaultprops:{ show: true, chips:true,multiple:true,deletablechips:true },
                  name: 'actions affichés',
                  value: 'none',
                  liste: listActions,
                  }" :change="(item,value,sel)=> {changeActions(item,value,sel,i) }" :valField="action.actions" />

                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

                  </v-tab-item>
              </v-tabs-items>
            </v-tabs>


        </v-tab-item>

        <!--
          //
          //
          Les actions
          //
          //
          -->
        <v-tab-item>

        <listActions :page="page" :module="module" @listActions="Actions"/>

        </v-tab-item>

        <!--
          //
          //
          Config des listes
          //
          //
          -->        

          <v-tab-item>
            
          
            <v-btn fab small @click="addListe"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="listFichesAccordion" class="pa-3">
              <v-expansion-panel v-for="(fiche, i) in displays.params.listFiches" :key="'lf'+i">
                <v-expansion-panel-header>
                  {{fiche.name}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeListe(i)" v-if="fiche.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template> 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">

                  <div>
                    <SwitchC
                    :item="{name:'activé',value:'set',
                    defaultprops:{show:true}}" 
                    :change="(item,value) => {fiche.set = value }"
                    :valField="fiche.set" />
                    <TextField                  
                    v-if="fiche.key!='default'"
                    :item="{name:'clé',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value) => {fiche.key = value } "
                    :valField="fiche.key"/>
                    <TextField                  
                    :item="{name:'nom de la liste',value:'name',defaultprops:{show:true}}" 
                    :keyup="(item,value) => {fiche.name = value } "
                    :valField="fiche.name" />
                    <SwitchC
                    :item="{name:'Peut être droppable',value:'isdroppable',
                    defaultprops:{show:true}}" 
                    :change="(item,value) => {fiche.isdroppable = value }"
                    :valField="fiche.isdroppable" />
                    <Select
                      :item="{name: 'table ciblée',value: 'table',liste: listTables,
                      defaultprops:{ show: true,clearable:true } } "
                      :change="(item,value) => { fiche.table = (value)?value['value']:''; } "
                      :valField="fiche.table"
                      />
                  </div>
                  {{ fiche.table }}
                  <v-tabs v-model="tabsFiche" v-if="fiche.table && fiche.table!=''">
                    <v-tab> Filtre </v-tab>
                    <v-tab> Champs </v-tab>
                    <v-tab v-show="!fiche.isdroppable"> Relationnel pour ajout</v-tab>
                    <v-tab v-show="fiche.isdroppable"> Update de champ sur le drop</v-tab>
                    <v-tabs-items v-model="tabsFiche">
                      <v-tab-item>
                        <Filtre :page="page" :filtre="fiche.filtre" :table="fiche.table" />
                      </v-tab-item>
                      <v-tab-item>
                        <p>Permet de créer le tableau montrant la liste.</p>
                        <p>activez l'affichage du champ pour voir sa colonne dans le tableau.</p> 
                          <p>lors du click, mentionnez le AS "ongletname" pour l'affichage dans l'onglet et ongletcolor pour sa couleur </p>
                        Les champs non affichés permettent la jonction entre la table {{ fiche.table }} et {{ module.table }} lors de l'ajout. 
                        <Fields :page="page" :champs="fiche.champs" :filter="fiche.filtre" :table="fiche.table" />
                        </v-tab-item>
                      <v-tab-item v-show="!fiche.isdroppable">
                        La jonction permet  d'alimenter les champs de la table {{ module.table }} en fonction des résultat d'une ligne de cette liste.
                        (Juste les champs hors des 4 liaisons primaires listés dans "requetes et variables")
                        <ArrayTable 
                        :item="{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:fieldsInModuleTable},{type:'',key:'value',name:'Valeur',description:'variables [[line||mon_as]] pris en compte'}]},
                        defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,tableAdd:true,addTitle:'ajouter un champ à updater'}}"
                        :valField="fiche.relationfields"
                        :change="(item,value) => { fiche.relationfields = value; } "
                        />  
                        </v-tab-item>
                        <v-tab-item v-show="fiche.isdroppable">
                          <v-row>
                            <v-col>
                            Champs updatés lors du drop sur la tabulation : 
                            <ArrayTable 
                            :item="{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:fieldsInModuleTable},{type:'',key:'value',name:'Valeur',description:'aucune variable pris en compte'}]},
                            defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,tableAdd:true,addTitle:'ajouter un champ à updater'}}"
                            :valField="fiche.updateondroptab"
                            :change="(item,value) => { fiche.updateondroptab = value; } "
                            />   
                            </v-col>
                            <v-col>
                              Champs updatés lors du drop sur le planning : 
                            <ArrayTable 
                            :item="{otherparams:{arrayTable:[{type:'select',key:'key',name:'Clé de la table',ArrayValue:fieldsInModuleTable},{type:'',key:'value',name:'Valeur',description:'aucune variable pris en compte'}]},
                            defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,tableAdd:true,addTitle:'ajouter un champ à updater'}}"
                            :valField="fiche.updateondropplann"
                            :change="(item,value) => { fiche.updateondropplann = value; } "
                            />                                
                              </v-col>
                            </v-row>
                            <SwitchC
                            :item="{name:'Garder la tranche de date lors du drop sur le planning',value:'keepperiod',
                            defaultprops:{show:true}}" 
                            :change="(item,value) => {fiche.keepperiod = value }"
                            :valField="fiche.keepperiod" />
                        </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>

                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

          </v-tab-item>

      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: "PlanningModule",
  props: ["module", "page"],

  components: {
    Autocomplete: () => import("../../fields/autocomplete.vue"),
    //SimpleTable: () => import("../outils/simpleTable.vue"),
    SwitchC: () => import("../../fields/switch.vue"),
    Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../outils/constructField/Field.vue"),
    TextField: () => import("../../fields/textfield.vue"),
    ArrayTable   : () => import('../../fields/arrayTable.vue'),
    groupConditions : () => import('../outils/constructConditions/Conditions.vue'),
    Cpage : () => import('../outils/constructPage/Page.vue'),
    listActions: () => import("../outils/listActions.vue"),
    Select: () => import("../../fields/select.vue"),
  },

  data: function () {
    return {
      tab: "",
      tab2: "",
      accordionEncart : false,
      accordionTooltip : false,
      accordionAction : false,
      accordionQuery : false,
      displays: this.module.displays,
      fieldsInModuleTable : [],
      listActions : [],
      listTables : [],
      aff : '',
      listlignes : '',
      listGrouplignes : '',
      listFichesAccordion:'',
      tabsFiche : ''
    };
  },

  watch: {},

  methods: {


    Actions(datas){
      this.listActions = []
      datas.forEach((v) => {
        this.listActions.push({text:v.name,value:v.id});
      })
    },

    changeActions(item,value,selectedKey,row) {
      var actions = []
      for(var selected in value) {
        if(typeof value[selected] == 'object') { actions.push(value[selected][selectedKey]) } else { actions.push(value[selected]); }
      }
      this.displays.params.display.actions[row].actions = actions
      this.$emit('recepVal',this.displays); 
    },

    removeVariantListe(key) {
      this.displays.params.queryList = this.displays.params.queryList.filter(
        (el, i) => i != key
      );
      this.$emit("recepVal", this.displays);
    },
   

    addVariant(enctool) {
    
    var defaultEncart = this.displays.params.display[enctool].find((el) => el.key ==='default')
    let copieDefault = JSON.parse(JSON.stringify(defaultEncart))
    console.log(defaultEncart)
    copieDefault.key="nouveau variant"
    this.displays.params.display[enctool].push(copieDefault);
    this.$emit('recepVal',this.displays);
  },
  removeVariantEncart(key,enctool) {
    this.displays.params.display[enctool] = this.displays.params.display[enctool].filter((el,i)=> i!=key);
    this.$emit('recepVal',this.displays);
  },

  changeNameVariantEncart(item,value,selectedKey,key,enctool) {
    var res = (typeof value ==='object') ? value[selectedKey] : value
    this.displays.params.display[enctool][key][item.value] = res
    this.$emit('recepVal',this.displays);
  },

  addCondition(row,enctool) {
    let newCondition = JSON.parse(JSON.stringify(this.newCondition));
    this.displays.params.display[enctool][row]['conditions'].push(newCondition)
  },

  updateConditions(value,enctool,row) {
    this.displays.params.display[enctool][row]['conditions'] = value
    this.$emit('recepVal',this.displays);      
  },

  changeConditon(item,value,selectedKey,row,rowcondition,enctool) {
    var res = (typeof value ==='object') ? value[selectedKey] : value
    this.displays.params.display[enctool][row]['conditions'][rowcondition][item.value] = res
    this.$emit('recepVal',this.displays);
  },

  removeCondition(row,rowcondition,enctool) {
    this.displays.params.display[enctool][row]['conditions'] = this.displays.params.display[enctool][row]['conditions'].filter((el,i)=> i!=rowcondition);
  },


  addListe() {
    
    var defaultEncart = this.displays.params.listFiches.find((el) => el.key ==='default')
    let copieDefault = JSON.parse(JSON.stringify(defaultEncart))
    copieDefault.name="nouvelle liste"
    copieDefault.key="newliste"
    copieDefault.table = ''
    copieDefault.filtre = []
    copieDefault.champs = []
    this.displays.params.listFiches.push(copieDefault);
  },

  removeListe(index) {
    this.displays.params.listFiches = this.displays.params.listFiches.filter((el,i)=> i!=index);
  }

  },

      mounted() {
        
                //récupère tous les champs pour la liaison date début et fin de la table du module
              this.getListField({filter:{table:this.module.table}}).then((resp) => {      
                if(Array.isArray(resp)) {
                    resp.forEach((field) => {
                        this.fieldsInModuleTable.push({"text":field.displays.name+' ('+field.field+')',"value":field.cle})
                    })                             
                }                                                
              });
              
              this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
                    resp.data.forEach((table) => {
                        this.listTables.push({text:table,value:table})
                    })
                    
                })

      },
};
</script>


<style scoped>

.v-application .pa-4 {
  padding : 0px !important;
}
</style>
