var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2","shaped":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Paramètres du module PDF ")])],1),(!_vm.displays.params.root)?_c('div',[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.typegenerate)+" "),_c('Select',{attrs:{"item":{name: 'Comment souhaitez-vous créer le document ?',value: 'type',liste: [{ text: 'Générer le document', value: 'generate' },{ text: 'mentionner le chemin du fichier', value: 'manual' }],
          defaultprops:{ show: true, column: false }},"change":(item,value,select) => { _vm.typegenerate = value[select]; _vm.type },"valField":_vm.typegenerate}}),(_vm.typegenerate=='manual')?_c('TextField',{attrs:{"item":{ name: 'Chemin du fichier', value: 'root' ,defaultprops:{ show: true }},"valField":_vm.manual,"keyup":(item,model)=> { _vm.manual = model; _vm.displays.params.root =model;  }}}):_vm._e(),_c('v-btn',{attrs:{"disabled":!_vm.typegenerate || (_vm.typegenerate=='manual' && _vm.manual=='')},on:{"click":_vm.generate}},[_vm._v(" Appliquer ")])],1)],1)],1):_c('div',[_c('v-tabs',{attrs:{"align":"start","background-color":"blue lighten-5"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Paramètres généraux ")]),_c('v-tab',[_vm._v(" requetes ")]),_c('v-tab',[_vm._v(" Champs ")]),_c('v-tab',[_vm._v(" Stockage ")]),_c('v-tabs-items',{staticClass:"pl-4",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',{staticClass:"ma-1"},[_c('TextField',{attrs:{"item":{ name: 'chemin du document', value: 'root' ,defaultprops:{ show: true }},"valField":_vm.displays.params.root,"keyup":(item,model,selectedKey)=> { _vm.displays.params.root = model; _vm.$emit('recepVal',_vm.displays); }}}),_c('p',[_vm._v("Vous pouvez utiliser la variable [[pdf||mon_as]] pour personnaliser le nom (ne pas mettre l'extension .pdf)")]),_c('TextField',{attrs:{"item":{ name: 'Nom du document', value: 'documentname' ,defaultprops:{ show: true }},"valField":_vm.displays.params.documentname,"keyup":(item,model,selectedKey)=> { _vm.displays.params.documentname = model; _vm.$emit('recepVal',_vm.displays); }}}),_c('SwitchC',{attrs:{"item":{name: 'Afficher le bouton de téléchargement',value: 'download',
                defaultprops:{ show: true, column: false }},"change":(item,model,selectedKey)=> { _vm.displays.params.download = model; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.download}}),_c('TextField',{attrs:{"item":{ name: 'height', value: 'height' ,defaultprops:{ show: true }},"valField":_vm.displays.params.iframe.height,"keyup":(item,model)=> { _vm.displays.params.iframe.height = model; _vm.$emit('recepVal',_vm.displays); }}}),_c('TextField',{attrs:{"item":{ name: 'width', value: 'width' ,defaultprops:{ show: true }},"valField":_vm.displays.params.iframe.width,"keyup":(item,model)=> { _vm.displays.params.iframe.width = model; _vm.$emit('recepVal',_vm.displays); }}}),_c('Select',{attrs:{"item":{name: 'Orientation',value: 'orientation',liste: [{ text: 'paysage', value: 'landscape' },{ text: 'portrait', value: 'table' }],
                defaultprops:{ show: true, column: false }},"change":(item,value) => { _vm.displays.params.orientation = value.value; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.orientation}})],1)],1)],1),_c('v-tab-item',[_c('Filtre',{attrs:{"filtre":_vm.displays.filter,"table":_vm.module.table},on:{"recepVal":_vm.changeFilter}})],1),_c('v-tab-item',[_c('Fields',{attrs:{"table":_vm.module.table,"champs":_vm.displays.params.champs,"filter":_vm.displays.filter,"page":_vm.page},on:{"recepVal":_vm.changeEC}})],1),_c('v-tab-item',[_vm._v(" Vous pouvez stocker le PDF une fois celui-ci généré. "),_c('Select',{attrs:{"item":{name: 'Configuration de stockage',value: 'type',liste: [{ text: 'pas de stockage', value: '' },{ text: 'dans une table', value: 'table' },{ text: 'dans la GED', value: 'ged' }],
          defaultprops:{ show: true, column: false }},"change":(item,value,select) => { _vm.displays.params.storage.type = value[select]; _vm.$emit('recepVal',_vm.displays); },"valField":_vm.displays.params.storage.type}}),(_vm.displays.params.storage.type=='ged' && _vm.listGedModule.length)?_c('div',[_vm._v(" Pour stocker le document dans la GED, vous devez créer un module GED pour les paramétrages. "),_c('Select',{attrs:{"item":{name: 'Choix du module GED',value: 'type',liste: _vm.listGedModule,
          defaultprops:{ show: true, column: false }},"change":(item,value) => {_vm.displays.params.storage.ged.module = value['value']; _vm.$emit('recepVal',_vm.displays);},"valField":_vm.displays.params.storage.ged.module}}),_vm._v(" Param à faire passer pour ouvrir la GED "),_c('ArrayTable',{attrs:{"item":{otherparams:{
              arrayTable:[
                  {type:'',key:'name',name:'nom du paramètre'},
                  {type:'',key:'value',name:'valeur du paramètre',description:'Vous pouvez insérer la variable d\'envirronnement [[line||mon_as]]'}]}
          ,defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter un paramètre pour l\'utilisation de la GED'}},"valField":_vm.displays.params.storage.ged.params,"change":(item,value) => {_vm.displays.params.storage.ged.params = value; _vm.$emit('recepVal',_vm.displays);}}})],1):_vm._e(),(_vm.displays.params.storage.type=='table')?_c('div',[_vm._v(" Sélectionnez la table qui va faire l'objet de l'entrée "),_c('Select',{attrs:{"item":{name: 'Table',value: 'type',liste: _vm.listTables,
          defaultprops:{ show: true, column: false }},"change":_vm.tableStockage,"valField":_vm.displays.params.storage.params.table}}),_vm._l((_vm.fieldsTableStockage),function(field,i){return _c('div',{key:i},[_c('TextField',{attrs:{"item":{ name: field.table+' '+field.name, value: 'width' ,defaultprops:{ show: true }},"valField":_vm.displays.params.iframe.width,"keyup":(item,model)=> { _vm.displays.params.iframe.width = model; _vm.$emit('recepVal',_vm.displays); }}})],1)})],2):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }