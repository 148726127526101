<template>
  <v-card elevation="2" shaped class="ma-1">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Paramètres du module Formulaire </v-toolbar-title>
    </v-toolbar>

    <Select
    :item="{name: 'Type de sauvegarde',value: '',liste: [{text:'Sur page formulaire de la table du module (form de vuex)',value:''},{text:'sur localStorage',value:'localstorage'},{text:'sur vuex avec clé personnalisée',value:'vuex'},{text:'sauvegarde indépendante (necessaite valeurs prédéfinis)',value:'inside'}],
    defaultprops:{ show: true}}"
    :change="(item,value,select) => { displays.params.typesave = value['value']; $emit('recepVal',displays); }"
    :valField="displays.params.typesave"
    />  

    <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Champs à afficher </v-tab>
          <v-tab> Valeurs prédéfinis (hors sauvegarde sur page formulaire)</v-tab>
          <v-tab v-if="displays.params.typesave!='classic'"> Type de sauvegarde si front</v-tab>
          <v-tabs-items v-model="tab" class="pl-4">
            <v-tab-item>
              <SwitchC
              :item="{name:'Affichage sur une ligne',value:'online',defaultprops:{show:true}}" 
              :change="(item,model) => {displays.params.online = model; }"
              :valField="displays.params.online" />
              <v-row>
                <v-col class="ma-1">
                  <Autocomplete
                    :item="item"
                    keyup=""
                    :valField="displays.params.champs"
                    :change="onChange"
                  />

                  <SimpleTable
                    :Headers="[
                      { text: 'crée', value: 'create' },
                      { text: 'Nom du champ', value: 'nameoption' },
                      { text: 'clé', value: 'cle' },
                      { text: 'customisé', value : 'variant'},
                      { text: 'sur formulaire', value: 'form' },
                      { text: 'sur recherche', value: 'search' },
                    ]"
                    :datas="dataTableField"
                    :editTable="[{ icon: 'mdi-pencil', action: editField }]"
                    @recepVal="changeFieldOrder"
                    :isDraggable="true"
                    :del="false"
                  />
                </v-col>
              </v-row>

            </v-tab-item>

          <v-tab-item>
            <SwitchC
            :item="{name:'Activer l\'enregistrement indépendant',value:'set',defaultprops:{show:true}}" 
            :change="(item,model) => {displays.params.dependencies.set = model; $emit('recepVal',displays); }"
            :valField="displays.params.dependencies.set" />

            <div v-if="displays.params.dependencies.set">
              <TextField 
              :item="{name:'nom du bouton d\'enregistrement',value:'',defaultprops:{show:true}}" 
              :keyup="(item,value) => {displays.params.dependencies.buttonname = value;  $emit('recepVal',displays); }"
              :valField="displays.params.dependencies.buttonname" />
              <TextField 
              :item="{name:'class duformulaire',value:'',defaultprops:{show:true}}" 
              :keyup="(item,value) => {displays.params.dependencies.formclass = value;  $emit('recepVal',displays); }"
              :valField="displays.params.dependencies.formclass" />

              <p>Recherchez les valeurs de vos champs via cette requete. Les AS doivent correspondre à la clé des champs (valeur de produits.nom = clé : produitsNom)</p>
              <p>(Valide hors sauvegarde sur page formulaire de la table du module)</p>
          <v-tabs align="start" background-color="blue lighten-5" v-model="tab2">
              <v-tab>Filtre </v-tab>
              <v-tab> Champs</v-tab>
              <v-tabs-items v-model="tab2" class="pl-4">
                <v-tab-item>
                  
                  <Filtre :page="page" :filtre="displays.params.dependencies.datas.filtre" :table="module.table" @recepVal="(data)=> {displays.params.dependencies.datas.filtre = data; $emit('recepVal',displays); }" />
                </v-tab-item>
                <v-tab-item>
                  
                  <Fields :page="page" :champs="displays.params.dependencies.datas.champs" :filter="displays.params.dependencies.datas.filtre" :table="module.table" />
                </v-tab-item>
              </v-tabs-items>
          </v-tabs>

            </div>

          </v-tab-item>

          <v-tab-item v-if="displays.params.typesave!='classic'">

          <TextField 
            :item="{name:'Clé permettant la sauvegarde des informations',value:'',defaultprops:{show:true}}" 
            :keyup="(item,value) => {displays.params.paramsave.key = value;  $emit('recepVal',displays); }"
            :valField="displays.params.paramsave.key" />

          </v-tab-item>
          
          </v-tabs-items>
            </v-tabs>



  </v-card>
</template>



<script>
export default {
  name: "FormModule",
  props: ["module", "page"],

  components: {
    Autocomplete: () => import("../../fields/autocomplete.vue"),
    SimpleTable: () => import("../outils/simpleTable.vue"),
    SwitchC: () => import("../../fields/switch.vue"),
    Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../outils/constructField/Field.vue"),
    TextField: () => import("../../fields/textfield.vue"),
    Select: () => import("../../fields/select.vue"),
  },

  data: function () {
    return {
      tab : '',
      tab2 : '',
      item: {
        multiple: true,
        name: "Champs à afficher",
        value: "champs",
        

        liste: [],
        defaultprops:{
            show: true,
            chips: true,
            multiple : true,
            deletablechips: true,
            modelRules: [(v) => !!v || 'Au moins un champ doit etre renseigné'],
          }
      },
      allFields : [],
      displays: this.module.displays,
      dataTableField: [],
    };
  },

  watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false && val.propsPage.name=='editionfield') {
          this.getFromAPI();
        }
      },
      deep: true,
    },
  },

  methods: {
    editField(field) {
      if (field.create) {
        this.goTo({
          rootPage: this.page,
          name: "editionfield",
          type: "modal",
          replace: { id: field.id, variant : 0 },
          paramDialog: { width: 1000 },
        });
      } else {

        this.goTo({
          rootPage: this.page,
          name: "editionfield",
          type: "modal",
          replace: { name: field.name, table : field.table },
          paramDialog: { width: 1000 },
        });
      }
    },

    constructDataField() {
      this.dataTableField = [];
      var fields = this.allFields;
      this.allFields.forEach((field) => {
            if(this.displays.params.champs.includes(field.cle)) {
              var name = field.displays.name;
              if(field.parent) name = name+" (enfant de "+fields.find(el => el.id===field.parent).displays.name+")";
                var data = {
                  id: field.id,
                  table: field.table,
                  create: field.create,
                  name: field.displays.name,
                  nameoption : name,
                  variant: (field.displays.variant==0) ? 'non' : 'oui',
                  cle: field.cle,
                  form: field.format.form.type,
                  search: field.format.search.type,
                };
                this.dataTableField.push(data);
            }
      })
    },

    changeFieldOrder(data) {
      this.displays.params.champs = [];
      data.forEach((val) => {
        this.displays.params.champs.push(val.cle);
      });
      this.$emit('recepVal',this.displays);
    },

    onChange(item, model,selectedKey) {
      var champs = []
      for(var selected in model) {
        if(typeof model[selected] == 'object') { champs.push(model[selected][selectedKey]) } else { champs.push(model[selected]); }
      }
      this.displays.params.champs = champs;
      this.constructDataField();
      this.$emit('recepVal',this.displays);
    },

    getFromAPI() {
      this.getListField({filter:{table:this.module.table}}).then((resp) => {
          this.allFields = resp;
          this.constructArray();
          this.constructDataField();
        });
    },

    constructArray() {
      if (Array.isArray(this.allFields)) {
        this.allFields.forEach((field) => {

          this.item.liste.push({ text: field.displays.name, value: field.cle });
        });
      }
    },
  },

  mounted() {
    this.getFromAPI();
  },
};
</script>

