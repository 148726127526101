<template>

    <div v-if="show">
    <not-allowed v-if="!AllowedDisplay"></not-allowed>

    <div class="planning-container">

                    <v-row no-gutters v-if="listFiches.length">
                        <v-col class="elevation-1">
                            <v-tabs v-model="tabsFiches" class="blue" background-color="deep-purple lighten-4">

                                    <v-tab v-show="false"></v-tab>
                                    <v-tab v-for="(fiche,i) in listFiches" :key="'lf'+i"  
                                    :href="'#'+fiche.key" @click="callList(fiche,i)" 
                                    :data-key="fiche.key"
                                    style="border:2px solid;"
                                    :class="[ondragDiv && fiche.isdroppable ? 'blue lighten-3 elevation-2' : 'grey lighten-3',' rounded ma-1 pa-3',fiche.isdroppable ? 'droppable-tab' : '']">
                                    <v-icon v-if="fiche.isdroppable && ondragDiv">mdi-arrow-collapse-down</v-icon>
                                    {{ fiche.name }}</v-tab>

                                
                                <v-tabs-items v-model="tabsFiches" style="position:relative;">
                                    <v-tab-item></v-tab-item>
                                    <v-tab-item v-for="(fiche,i) in listFiches" :key="'lff'+i" :id="fiche.key">
                                        <v-progress-linear v-if="waitCallList"  color="blue" height="3" indeterminate></v-progress-linear>
                                        <SimpleTable
                                        v-if="fiche.list.length && !fiche.isdroppable"
                                        :Headers="fiche.headers"
                                        :datas="fiche.list"
                                        :editTable="[]"
                                        :hidefooter="false"
                                        @clickRow="putFicheForward"
                                    />

                                        <v-row no-gutters v-if="fiche.isdroppable">
                                            <div :style="{'background-color' : (list.ongletcolor) ? list.ongletcolor : '' }" 
                                            v-for="(list,f) in fiche.list" 
                                            :key="'fa'+f" 
                                            v-bind="generateDataAttributes(list)"
                                            class="pa-3 text-center ma-1 rounded elevation-1 iscoldraggable text-caption">{{ list.ongletname }}</div>
                                            <v-col v-if="!fiche.list.length && !waitCallList" style="text-align:center;"><b>Aucune fiche</b></v-col>
                                        </v-row>
   
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-tabs>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col  v-for="(fiche,i) in fichesForward" @click="selectFiche(fiche)" 
                            :key="'fiche-'+i" 
                            :class="['col-2 pa-3 text-center ml-1','rounded','ongletsforwards',ficheSelected===fiche ? 'elevation-2':'elevation-1']" 
                            :style="{'background-color':(fiche.ongletcolor)?fiche.ongletcolor:'#d3d3d3', 
                            'opacity' : ficheSelected===fiche ? '1':'0.7', 
                            'border' : ficheSelected===fiche ? '2px solid':'1px solid grey',
                            'cursor' : 'pointer'}">
                            {{ fiche.ongletname }}
                            <v-icon justify="end" @click="removeForward(fiche,i)">mdi-close</v-icon>
                        </v-col>
                    </v-row>

                    
                    <v-row no-gutters>
                        <v-col class="col-md-5 col-12 text-center text-md-left">
                            <v-btn-toggle
                            v-model="PlanningParams.displayAllDay"
                            mandatory
                            rounded
                            flat
                            >
                            <v-btn :value="0" small>Jour+horaire</v-btn>
                            <v-btn :value="1" small>Jour</v-btn>
                            <v-btn :value="2" small>Horaire</v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col class="col-md-2 col-12 text-center">
                            <v-btn small @click="prev"><v-icon>mdi-arrow-left</v-icon></v-btn>
                            <template v-if="$route.query.period!=0">
                                {{ moment($route.query.start).format('MMMM YYYY') }}
                            </template>
                            <template v-if="$route.query.period==0">
                                Semaine {{ moment($route.query.start).isoWeek() }}
                            </template>                           
                            <v-btn small @click="next"><v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-col>
                        <v-col class="col-md-5 col-12 text-center text-md-right">
                            <v-btn-toggle
                            v-model="selectedPeriod"
                            mandatory
                            rounded
                            flat
                            >
                            <v-btn value="0" small>Semaine</v-btn>
                            <v-btn value="1" small>1 Mois</v-btn>
                            <v-btn value="2" small>2 Mois</v-btn>
                            <v-btn value="3" small>3 Mois</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

         <!--debut du tableau-->
        <table class="planning-table">
        <thead>
            <tr>
            <th rowspan="3" colspan="2">Personnel</th>
            </tr>

            <tr>
                <th colspan="7" v-for="(week, index) in weeks" :key="'week-' + index">S{{ week.weekNumber }}</th>
            </tr>
            <tr>
                <th v-for="day in weeks.flatMap(week => week.days)" :key="day.format('YYYY-MM-DD')"
                    :class="{'weekend': isWeekend(day)}"
                    >{{ day.format('ddd') }}<br>{{ day.format('DD/MM') }}</th>
            </tr>
        </thead>
        <tbody>
            <!-- Nom des groupes-->

            <template v-for="(group, i) in listGroup">
            <tr :key="'gr'+i" class="namegroup">
                <td :colspan="weeks.flatMap(week => week.days).length+2"> {{  group.name }}</td>
            </tr>

            <!-- début de boucle des lignes-->
            <template v-for="(pers, p) in group.list">
            <tr 
            v-if="PlanningParams.displayAllDay===0 || PlanningParams.displayAllDay===1"
            class="planning-list-items" 
            :key="'per'+i+p">
            <!-- Première cellule avec rowspan -->
            <td :rowspan="[(PlanningParams.displayAllDay===1)?'1':'2']" class="pl-design">{{ pers.name }}</td>
            <td class="pl-design">J</td>

            <!-- Boucle sur les events en mode journée -->
             <!-- :style="calculHeightTd(day, pers.id)" c'est ce que je mettais pour calculer la hauteur du td pour l'ajuster avec les div -->
            <td 
                v-for="day in weeks.flatMap(week => week.days)" 
                :key="'day1-'+day.format('YYYY-MM-DD')+'-'+pers.line" 
                :data-day="day.format('YYYY-MM-DD')" 
                :data-line="pers.line" 
                :data-allday="true" 
                data-typeelement="td"                
                :class="['droppable-td','allday','getwidth',{'weekend': isWeekend(day)}]">
                

                    <!-- Contenu dynamique -->
                     <!-- :style="getDivStyle(event, day, pers.line,true)" c'est ce que je mettais pour mettre le div à la bonne hauteur en fonction des autres rdv-->
                    <div 
                    class="planning-divday-content isdivdraggable isdivresizable" 
                    v-for="event in indexedEvents[day.format('YYYY-MM-DD')]?.[pers.line] || []" 
                    :key="'event1-'+event.id" 
                    :data-day="event.nbdays" 
                    data-typeelement="div" 
                    v-bind="generateDataAttributes(event)" 

                    >

 
                        <v-tooltip right :disabled="ondragDiv" color="white" :open-delay="500" >
                            <template v-slot:activator="{ on, attrs }" >
                                <div v-on="on" v-bind="attrs">
                                <v-menu  v-model="menu[event.id]" nudge-bottom="0" nudge-left="0" min-width="150px" 
                                absolute 
                                :position-x="menuPosition.x"
                                :position-y="menuPosition.y"
                                :close-on-content-click="true">
                                    <template v-slot:activator="{ }">
                                    <div @contextmenu.prevent="openMenu($event, event.id)">
                                        <div class="planning-dot-state" :style="'background-color:'+event.dotcolor+';'"></div>
                                        <Cpage 
                                        :pageTemplate="event.encart" 
                                        :page="page" 
                                        :modal="modal" 
                                        :form="false" 
                                        hook="hook"
                                        :mod="false"/>
                                    </div>
                                    </template>
                                    <v-card>
                                        <div v-for="(act,a) in event.actions" :key="'bou'+a" class="text-left">
                                            <v-btn x-small width="100%" @click="onClickButton(event, act)" >
                                                <v-icon v-if="act.icon" small class="text-left">{{ act.icon }}</v-icon>
                                                {{ act.name }}</v-btn>
                                        </div>
                                        
                                    </v-card>
                                    </v-menu>     
                            </div>
                            </template>
                            <Cpage 
                            :pageTemplate="event.tooltip" 
                            :page="page" 
                            :modal="modal" 
                            :form="false" 
                            hook="hook"
                            :mod="false"/>

                        </v-tooltip> 

                    </div>

            </td>
            </tr>

            <!-- debut de boucle pour les events en horaire -->
        <tr :key="'per-row2-'+i+p"
        v-if="PlanningParams.displayAllDay===0 || PlanningParams.displayAllDay===2"
        class="trhours"
        >
            <td :rowspan="[(PlanningParams.displayAllDay===2)?'1':'2']" class="pl-design"
            v-if="PlanningParams.displayAllDay===2"
            >{{ pers.name }}</td>
            <td class="pl-design">H</td>
            <td 
                v-for="day in weeks.flatMap(week => week.days)" 
                :key="'day2-'+day.format('YYYY-MM-DD')+'-'+pers.line" 
                data-typeelement="td" 
                :class="['getwidth',{'weekend': isWeekend(day)}]"
                >

                <div class="droppable-div am"
                :data-day="day.format('YYYY-MM-DD')"
                :data-line="pers.line"
                :data-allday="false"
                data-demi="am"
                >
                    

                <div class="planning-newhour" @click="createNewHourEvent(day,pers.line,'am')">+am</div>

                        <!-- liste des RDV du matin -->
                        <div 
                        class="planning-divhour-content isdivdraggable" 
                        v-for="event in indexedHalfDayEvents[day.format('YYYY-MM-DD')]?.[pers.line]?.['am'] || []" 
                        :key="'event2-'+event.id" 
                        :data-day="event.nbdays" 
                        data-typeelement="div" 
                        v-bind="generateDataAttributes(event)">

                        <v-tooltip right :disabled="ondragDiv" color="white" :open-delay="500">
                                <template v-slot:activator="{ on }" >
                                    <div v-on="on">
                                    <v-menu  v-model="menu[event.id]" nudge-bottom="0" nudge-left="0" min-width="150px" 
                                    absolute 
                                    :position-x="menuPosition.x"
                                    :position-y="menuPosition.y"
                                    :close-on-content-click="true">
                                    <template v-slot:activator="{ }">
                                        <div @contextmenu.prevent="openMenu($event, event.id)">
                                            <div class="planning-dot-state" :style="'background-color:'+event.dotcolor+';'"></div>
                                            <Cpage 
                                            :pageTemplate="event.encart" 
                                            :page="page" 
                                            :modal="modal" 
                                            :form="false" 
                                            hook="hook"
                                            :mod="false"/>
                                        </div>
                                    </template>
                                        <v-card>
                                            <div v-for="(act,a) in event.actions" :key="'bou'+a" class="text-left">
                                            <v-btn x-small width="100%" @click="onClickButton(event, act)" >
                                                <v-icon v-if="act.icon" small class="text-left">{{ act.icon }}</v-icon>
                                                {{ act.name }}</v-btn>
                                            </div>
                                            
                                        </v-card>
                                    </v-menu>                                     
                                    </div>
                                </template>

                                <Cpage 
                                :pageTemplate="event.tooltip" 
                                :page="page" 
                                :modal="modal" 
                                :form="false" 
                                hook="hook"
                                :mod="false"/>
                        </v-tooltip>
                        </div>
                        <!-- FIN RDV matin -->

                </div>


                <hr class="hour-separator">


                <div class="droppable-div pm"
                :data-day="day.format('YYYY-MM-DD')"
                :data-line="pers.line"
                data-demi="pm"
                >

                        <!-- liste des RDV de l'apres midi-->
                        <div 
                        class="planning-divhour-content isdivdraggable" 
                        v-for="event in indexedHalfDayEvents[day.format('YYYY-MM-DD')]?.[pers.line]?.['pm'] || []" 
                        :key="'event2-'+event.id" 
                        :data-day="event.nbdays" 
                        data-typeelement="div" 
                        v-bind="generateDataAttributes(event)">
                        
                        <v-tooltip right :disabled="ondragDiv" color="white" :open-delay="500">
                                <template v-slot:activator="{ on }" >
                                    <div v-on="on">
                                    <v-menu  v-model="menu[event.id]" nudge-bottom="0" nudge-left="0" min-width="150px" 
                                    absolute 
                                    :position-x="menuPosition.x"
                                    :position-y="menuPosition.y"
                                    :close-on-content-click="true">
                                    <template v-slot:activator="{ }">
                                        <div @contextmenu.prevent="openMenu($event, event.id)">
                                            <div class="planning-dot-state" :style="'background-color:'+event.dotcolor+';'"></div>
                                            <Cpage 
                                            :pageTemplate="event.encart" 
                                            :page="page" 
                                            :modal="modal" 
                                            :form="false" 
                                            hook="hook"
                                            :mod="false"/>
                                        </div>
                                    </template>
                                        <v-card>
                                            <div v-for="(act,a) in event.actions" :key="'bou'+a" class="text-left">
                                            <v-btn x-small width="100%" @click="onClickButton(event, act)" >
                                                <v-icon v-if="act.icon" small class="text-left">{{ act.icon }}</v-icon>
                                                {{ act.name }}</v-btn>
                                            </div>
                                            
                                        </v-card>
                                    </v-menu>                                     
                                    </div>
                                </template>

                                <Cpage 
                                :pageTemplate="event.tooltip" 
                                :page="page" 
                                :modal="modal" 
                                :form="false" 
                                hook="hook"
                                :mod="false"/>
                        </v-tooltip>
                        </div>
                        <!-- FIN RDV apres midi-->

                        <div class="planning-newhour" @click="createNewHourEvent(day,pers.line,'pm')">+pm</div>


                </div>

          


            </td>
            </tr>
            </template>
        </template>

        </tbody>
        </table>
    </div>


    </div>

</template>

<script>


import mixins from './mixins'
import Cpage from '../../engine/outils/constructPage/Page'
import SimpleTable from "../../engine/outils/simpleTable.vue"
    export default {

    name :'PlanningModule',

    mixins : [mixins],

    props: ['idmodule','page','modal','parent','customparams','customquerys'],

    components : {
        Cpage,
        SimpleTable
        },

        watch : {
            '$route.query.start'(val) {
                this.querys.start = val
            this.callAPI()
            // Ajouter ici votre logique pour réagir au changement
            },
            
            selectedPeriod(newPeriod) {
                    

                    this.$router.push({ query: {...this.$route.query, period: newPeriod } }).catch(() => {});
                    this.querys.period = newPeriod
                    this.callAPI()

            },

            callBackOnCloseModal: {
            // permet de rafraichir quand la modal de modif du module de la page se ferme
            handler(val) {

                if (val.show === false && this.fromThisMod) {
                this.rafraichOneLigne(this.fromThisMod)
                this.fromThisMod = false
                }
            }, deep: true

            },
        },

        data: function() {

            return {

                show : false,
                AllowedDisplay : true,
                params:{},
                querys:{},

                listEvents : [],
                listGroup : [{name:'liste...',id:false,list:[{name:'ligne',line:false}]}],
                listFiches : [],
                tabsFiches : 'none',
                selectedFicheTabs : null,
                waitingElems :[{id:5,type:"pose","title":"evnew"},{id:6,type:"pose","title":"martin"}],

                ficheSelected : false,
                fichesForward : [],

                datePicker : this.$route.query.start,
                menu : [],
                menuPosition: { x: 0, y: 0 },
                fromThisMod : false,
                //permet d'afficher le progress linear lors de l'appel des listes
                waitCallList : false
            }
        },

        computed : {
            callBackOnCloseModal() {
            return this.$store.getters.modalShowParams({ type: 'from', val: this.page })
            },
        },

        methods :  {

            callAPI : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys}}).then((resp) => {

                        this.manageDatas(resp.data)
                            
                    })
    
                },  

                manageDatas(data) {
                    if(!data.success) {
                            this.AllowedDisplay = false; 
                            return false;
                            }
                            this.listGroup = data.lines.group
                            this.listEvents = []
                            this.listEvents = data.events
                            this.listFiches = data.listfiches
                            this.tabsFiches = ''
                            this.show = true 
                },

            //selectionne la fiche sur le click de la ligne
            putFicheForward(line) {
                //insertion dans la liste
                const exists = this.fichesForward.some(fiche => fiche.id === line.id);
                if (!exists) {
                this.fichesForward.push(line);
                }
                //select auto
                this.ficheSelected = line
                //retour au planning
                this.tabsFiches = null
            },

            removeForward(fiche,index) {
                const selected = this.fichesForward.some(list => fiche.id === list.id);
                if (selected) {
                    this.ficheSelected = false
                }
                this.fichesForward = this.fichesForward.filter((el,i) => i!=index)

            },

            callList(fiche,i) {
                
                //si l'onglet est deja ouvert et qu'on reclique dessus il se ferme :
                if(this.tabsFiches==fiche.key) this.tabsFiches = ''
                if(this.listFiches[i].list.length && !fiche.isdroppable) return;
                this.waitCallList = true;
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,getlistkey:fiche.key,params:this.params,querys:this.querys}}).then((resp) => {

                    this.$set(this.listFiches,i,resp.data)
                    this.waitCallList = false;
                })                
            },

            resizeStart() {

            },

            onResize() {

            },

            onDropTab(event,tab) {

                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'droptab',fiche:event.dataset.id,key:tab.dataset.key}}).then((resp) => {
                    if(resp.data.success) {
                        this.listEvents = this.listEvents.filter(el => el.id != event.dataset.id)
                        // on recharge la liste d'event dans l'onglet concerné :
                        this.listFiches.forEach((fiche,i) => {
                            if(fiche.key== tab.dataset.key) {
                                this.callList(fiche,i)
                                this.tabsFiches = fiche.key;
                            }
                        })
                    } else {
                        this.$toast.error(resp.data.message)
                    }
                    
                })
            },

            onDropCol(event,elements) {
                
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'droplist',fiche:event.dataset.id,start:elements.start,end:elements.end,line:elements.line,allday:elements.allday,key:event.dataset.list_key}}).then((resp) => {
                    if(resp.data.success) {
                        // on recharge la liste d'event dans l'onglet concerné :
                        this.listFiches.forEach((fiche,i) => {
                            if(fiche.key== event.dataset.list_key) {
                                
                                this.callList(fiche,i)
                                this.tabsFiches = fiche.key;
                            }
                        })
                        // on rajouter l'event dans le planning :
                        this.listEvents.push(resp.data.events[0])                        
                    } else {
                        this.$toast.error(resp.data.message)
                    }
                    
                }) 
            },

            resizeStop(el) {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'resize',event:el}}).then(() => {

                })

                
            },

            selectFiche(line) {
                this.ficheSelected = line
            },

            createNewDayEvent(elements) {
                //elements : {start:yyyy-mm-dd hh:mm:ss, end:yyyy-mm-dd hh:mm:ss, line:perosnne concerné}
                if(this.ficheSelected) {

                    if(confirm('Confirmer l\'insertion ?')) {
                        this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'insert',fiche:this.ficheSelected,start:elements.start,end:elements.end,line:elements.line,allday:true}}).then((resp) => {

                        if(resp.data.success) {
                            this.listEvents.push(resp.data.events[0])
                        } else {
                            this.$toast.error(resp.data.message)
                        }
                        })                 
                    }      
                    this.ficheSelected = false; 
                } 

            },

            createNewHourEvent(day,line,when) {
                if(this.ficheSelected) {
                    let start = day.clone()
                    let end = day.clone()
                    if(when=='am') {
                        start = start.clone().hour(this.PlanningParams.AMHourStart).minute(this.PlanningParams.AMMinStart).format('YYYY-MM-DD HH:mm:ss')
                        end = end.clone().hour(this.PlanningParams.AMHourEnd).minute(this.PlanningParams.AMMinEnd).format('YYYY-MM-DD HH:mm:ss') 
                    }
                    if(when=='pm') {
                        start = start.clone().hour(this.PlanningParams.PMHourStart).minute(this.PlanningParams.PMMinStart).format('YYYY-MM-DD HH:mm:ss')
                        end = end.clone().hour(this.PlanningParams.PMHourEnd).minute(this.PlanningParams.PMMinEnd).format('YYYY-MM-DD HH:mm:ss') 
                    }

                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'insert',fiche:this.ficheSelected,start:start,end:end,line:line,allday:false}}).then((resp) => {

                        if(resp.data.success) {
                            this.listEvents.push(resp.data.events[0])
                        } else {
                            this.$toast.error(resp.data.message)
                        }
                    })

                } else {
                    this.$toast.warning('veuillez selectionner une fiche ')
                }

            },
            

            onDropEvent(element) {

                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,action:'resize',event:element.el}}).then(() => {

                })    
            },

            openMenu(event, index) {
            // Récupérer les coordonnées du curseur
            this.menuPosition.x = event.clientX;
            this.menuPosition.y = event.clientY;

            // Ouvrir uniquement le menu de l'élément correspondant
            this.$set(this.menu, index, true);
            },




            /*eslint-disable*/
            onClickButton: function (item, men) {
            if(men.target=='modal') this.fromThisMod = item.id;    
            this.clickActions({args:item.otherfields,action:men,idmodule:this.idmodule,page:this.page,
            success:(datas) => {
                if(datas.message) this.$toast.success(datas.message);
                if(men.typelink == 'api') {
                    //rafraich de l'event
                    this.rafraichOneLigne(item.id)
                }
            }    
            })

            },

            rafraichOneLigne(id) {
                this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,getline:id}}).then((resp) => {

                if(resp.data.success) {
                    const index = this.listEvents.findIndex(event => event.id === id);
                    if(resp.data.events[0]) {
                        this.$set(this.listEvents, index, resp.data.events[0]);
                    } else {
                        this.listEvents = this.listEvents.filter(event => event.id != id)
                    }
                } else {
                    this.$toast.error(resp.data.message)
                }
                }) //fin API                
            }

        },

        mounted() {
            
        },

        created() {
            this.show = true;
            this.params = this.$store.getters.specificPage(this.page).params    
            this.querys = this.$store.getters.specificPage(this.page).querys
            let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
        }

    }

</script>


<style scoped>

.planning-table {
    border:1px solid;
    border-collapse:collapse;
    width:100%;
    position: relative;
}
.planning-table th {
    font-size:13px;
    font-weight:normal;
    border:1px solid grey;
    padding:2px;
}

.planning-container {
  overflow-x: auto; /* Permet le défilement horizontal */
  white-space: nowrap; /* Empêche le contenu de se casser en plusieurs lignes */
  padding:10px;
}
.planning-table tbody td {
    position: relative;  /* Permet au contenu absolu de se positionner relativement à chaque td */
    vertical-align: top; /* Assure que le contenu reste aligné en haut */
    padding: 0; /* Supprime tout espace interne */
    margin: 0;
    height: 0px; /* Définissez une hauteur si nécessaire pour vos td */
    min-height:20px;
    border : 1px solid grey;

}


.planning-divday-content {
    position: absolute; /* Permet un positionnement précis */
    box-sizing: border-box; /* Inclut padding et border dans width/height */
    border: 1px solid grey;
    border-radius: 3px;
    margin: 1px; /* Ajoute un espace interne */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
    z-index:2;
    overflow: hidden; /* Masque tout ce qui dépasse */
    white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
}
.planning-divday-content > div {
    left:1px;
    top:0px;
    position:absolute;
    width:100%;
}
.planning-divhour-content > div {
    left:1px;
    top:0px;
    position:absolute;
    width:100%;
}
/*permet de ne pas sauter les lignes dans les div enfants */
.planning-divday-content .v-card,.planning-divhour-content .v-card   {
    white-space: nowrap !important;
}
.planning-container .v-data-table td, .v-data-table th {
    height:15px !important;
}
:deep().v-data-table td {
    height:15px !important;
}

/* le div qui affiche les event à l'horaire */
.planning-divhour-content {
    position:relative;
    border: 1px solid grey;
    border-radius: 3px;
    margin: 1px; /* Ajoute un espace interne */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
    white-space: nowrap; 
    font-size:12px;
}
/* div créé dynamiquement dans mixins.js : affiche un div fantome du nouvel event journee */
:deep().planning-divday-content-new {
    position: absolute; /* Permet un positionnement précis */
    box-sizing: border-box; /* Inclut padding et border dans width/height */
    border: 1px solid grey;
    border-radius: 3px;
    margin: 1px; /* Ajoute un espace interne */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden; /* Masque tout ce qui dépasse */
    white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
    opacity:0.5;
    background-color: #005dc7;
    z-index:1000;
}

.planning-table .planning-dot-state {
    position:absolute;
    top:0;
    left:0;
    border-radius:2px;
    height:7px;
    width:7px;
    border:1px solid grey;
}

.droppable-td.allday {
    background-color:aliceblue;
}
.droppable-td.allday.drop-activated,.drop-activated {
    background-color:rgb(177, 206, 238);
}

.planning-newhour {
    font-size:11px;
    border: 1px solid rgb(228, 228, 228);
    opacity:0.3;
    min-height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    box-sizing: border-box;
}
.planning-newhour:hover {
    opacity:0.8;
    cursor:pointer;
    
}

.pl-design {
    text-align:center;
    vertical-align: middle !important;
    width:2px;
}


.hour-separator {
    border-color:black
}
.trhours:nth-child(even) {
  background-color: #eeeeee; /* Vert clair */
}

.namegroup {
    text-align:center;
}
.weekend {
    background-color: #F6D8CE !important;
}

.iscoldraggable,.isdivresizable {
    user-select: none; /* Désactive la sélection */
}


</style>