<template>
  <v-form ref="form" v-model="valid" v-if="APILoaded" lazy-validation>
    <modal-template
      :pageTemplate="page"
      :main="false"
      :visible="showModal"
    ></modal-template>
    <v-row>
      <v-col class="col-md-4">
        <Select
          :item="{
            name: 'Type de module',
            value: 'type',
            liste: [
              { text: 'table', value: 'table' },
              { text: 'formulaire', value: 'form' },
              { text: 'recherche', value: 'search' },
              { text: 'calendrier', value: 'calendar' },
              { text: 'tabulation', value: 'tabs' },
              { text: 'pdf', value: 'pdf' },
              { text: 'Bilbiotheque d\'image', value: 'bibliotheque' },
              { text: 'Envoi d\'email', value: 'sendemail' },
              { text: 'Front datas', value: 'frontdatas' },
              { text: 'GED', value: 'ged' },
              { text: 'planning', value: 'planning' },
              { text: 'Ventilation', value: 'ventilation' },
              { text: 'Code barre', value: 'codebarre' }, 
              { text: 'Echarts', value: 'echarts' },
            ],
            defaultprops: {
              show: true,
              modelRules: [(v) => !!v || 'Ce champ doit etre renseigné'],
              disabled: module.id ? true : false,
            },
          }"
          :valField="module.type"
          :change="changeParam"
        />

        <Autocomplete
          :item="{
            name: 'table',
            value: 'table',
            liste: itemTableListe,
            defaultprops: {
              show: true,
              chips: false,
              deletablechips: false,
              modelRules: [(v) => !!v || 'Ce champ doit etre renseigné'],
              disabled: module.id ? true : false,
            },
          }"
          :valField="module.table"
          :change="changeParam"
        />

        <TextField
          :keyup="changeParam"
          :valField="module.name"
          :item="{
            name: 'Nom du module',
            value: 'name',
            defaultprops: { show: true, modelRules: [], class: 'pa-0 ma-0' },
          }"
        />

        <TextArea
          :keyup="changeParam"
          :valField="module.description"
          :item="{
            name: 'description',
            value: 'description',
            defaultprops: {
              show: true,
              modelRules: [],
              class: 'pa-0 ma-0',
              row: 1,
              filled: true,
              autogrow: true,
            },
          }"
        />

        <div v-if="module.type != '' && module.table != ''">
          <v-btn @click="openSaveTemplate = !openSaveTemplate"
            >Templates de module</v-btn
          >
          <loadSaveTemplate
            v-if="APILoaded"
            @closeLST="
              () => {
                openSaveTemplate = !openSaveTemplate;
              }
            "
            :page="page"
            :openModal="openSaveTemplate"
            type="moduleparams"
            :templateToSave="
              $store.getters.listForm(page)['fields']['displays']
            "
            @loadTemplate="loadTemplate"
          />
        </div>
      </v-col>

      <v-col class="col-md-8">
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> défaut </v-tab>
          <v-tab v-if="this.module.id"> par compagnie </v-tab>
          <v-tabs-items v-model="tab" class="pa-1">
            <v-tab-item>
              <component
                v-if="APILoaded"
                v-bind:is="type"
                :module="module"
                :page="page"
                :key="'r' + reloadComp"
                :itemTableListe="itemTableListe"
                @recepVal="changeDisplay"
              />
            </v-tab-item>

            <v-tab-item v-if="this.module.id">
              <!--- PARAM PAR COMPAGNIE -->
              <v-row v-for="(etps, i) in listEntreprises" :key="i" no-gutters>
                <v-col>{{ etps.nom }}</v-col>
                <v-col>
                  <div>
                    <v-btn
                      icon
                      @click="
                        goTo({
                          rootPage: page,
                          type: 'modal',
                          name: 'editvariantmodule',
                          replace: { id: module.id, variant: etps.id },
                          paramDialog: { width: 1000 },
                        })
                      "
                    >
                      <v-icon v-if="module.listvariant.includes(etps.id)"
                        >mdi-pencil</v-icon
                      >
                      <v-icon v-else>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 text-center">
        <v-btn class="primary" @click="validate" :disabled="!valid"
          >Enregistrer</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import recherche from "./Recherche";
import formulaire from "./Formulaire";
import table from "./Table";
import notype from "./noType";
import calendar from "./Calendar/Calendar.vue";
import tabs from "./Tabulations/Tabs.vue";
import pdf from "./Pdf.vue";
import bibli from "./Bibliotheque.vue";
import sendemail from "./Sendemail.vue";
import frontdatas from "./Frontdatas.vue";
import ged from "./Ged.vue";
import ventilation from "./Ventilation.vue";
import planning from "./Planning.vue";
import codebarre from "./codeBarre.vue";
import echarts from "./Echarts.vue";
export default {
  name: "EditModuleMain",

  props: ["page", "modal"],

  components: {
    Select: () => import("../../fields/select.vue"),
    Autocomplete: () => import("../../fields/autocomplete.vue"),
    TextField: () => import("../../fields/textfield.vue"),
    TextArea: () => import("../../fields/textarea.vue"),
    loadSaveTemplate: () =>
      import("../../main/templates/page/Edit/loadAndSaveTemplate.vue"),
  },

  data: function () {
    return {
      showModal: false,
      urlSend: "",
      itemTableListe: [],
      module: {},
      listParams: {},
      type: notype,
      valid: true,
      fields: [],
      APILoaded: false,
      query: {},
      tab: false,
      listEntreprises: [],
      openSaveTemplate: false,
      reloadComp: 0,
    };
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("getoverlay", true);
        this.API({
          type: "put",
          url: "engine/module/edit",
          params: {
            id: this.args.id,
            fields: this.$store.getters.listForm(this.page)["fields"],
            variant: 0,
          },
        }).then((resp) => {
          var isModal = this.$store.getters.modalShowParams({
            type: "to",
            val: this.page,
          });
          // si on a inséré une nouvelle ligne, on va changer l'urtl pour mettre le bon id
          if (isModal && isModal.params.id == "new") {
            this.module = resp.data.module;

            var newurl = isModal["propsPage"]["path"];
            newurl = newurl.replace(":id", resp.data.module.id);
            this.$store.dispatch("changeOptionsModal", {
              modal: { type: "to", val: this.page },
              type: "url",
              value: newurl,
            });
            this.$store.dispatch("changeOptionsModal", {
              modal: { type: "to", val: this.page },
              type: "params",
              key: "id",
              value: resp.data.module.id,
            });
            this.urlSend = newurl;
          }
          this.$store.dispatch("getoverlay", false);
        });
      }
    },

    constructParamModule(data) {
      this.APILoaded = false;
      this.itemTableListe = [];
      //on récupère les tables pour le choix de table dans le module
      data.dependencies.listTables.forEach((tables) => {
        this.itemTableListe.push({ text: tables, value: tables });
      });
      //injection des valeurs du module

      this.module = data.module;
      delete this.module.allFields;
      delete this.module.actions;

      this.$store.dispatch("pushFirstForm", {
        page: this.page,
        values: this.module,
      });

      this.fields = data.module.allFields;
      this.listParams = data.dependencies.listParams;
      this.getTemplate();
      this.APILoaded = true;
    },

    //appel des infos du module
    getModuleFromAPI() {
      this.API({
        type: "post",
        url: "engine/module/edit",
        params: { filter: { id: this.args.id, variant: 0 } },
      }).then((resp) => {
        this.constructParamModule(resp.data);
      });
    },

    //le changement de paramètre se fait uniquement lors de la création d'une nouveau module. il n'est plus possible de le modifier par la suite.
    changeParam(item, model, selectedKey) {
      var realValue = typeof model === "object" ? model[selectedKey] : model;
      this.module[item.value] = realValue;
      this.$store.dispatch("pushForm", {
        page: this.page,
        value: realValue,
        champ: item.value,
      });

      // si la table et le type de module est remplis, on récupère les paramètres du type de module etaffiche le component adéquate
      if (
        this.module.table != "" &&
        this.module.type != "" &&
        (item.value == "table" || item.value == "type")
      ) {
        this.API({
          type: "post",
          url: "engine/module/paramsnewmodule",
          params: { type: this.module.type, table: this.module.table },
        }).then((resp) => {
          this.constructParamModule(resp.data);
        });
      }
    },

    //modification du display
    changeDisplay(data) {
      this.$store.dispatch("pushForm", {
        page: this.page,
        value: data,
        champ: "displays",
      });
    },

    loadTemplate(data) {
      this.module.displays = data;
      this.$store.dispatch("pushForm", {
        page: this.page,
        value: data,
        champ: "displays",
      });
      this.reloadComp++;
      this.openSaveTemplate = false;
    },

    getTemplate() {
      this.type = notype;

      switch (this.module.type) {
        case "table":
          this.type = table;
          break;
        case "form":
          this.type = formulaire;
          break;
        case "search":
          this.type = recherche;
          break;
        case "calendar":
          this.type = calendar;
          break;
        case "tabs":
          this.type = tabs;
          break;
        case "pdf":
          this.type = pdf;
          break;
        case "bibliotheque":
          this.type = bibli;
          break;
        case "sendemail":
          this.type = sendemail;
          break;
        case "frontdatas":
          this.type = frontdatas;
          break;
        case "ged":
          this.type = ged;
          break;
        case "ventilation":
          this.type = ventilation;
          break;
        case "planning":
          this.type = planning;
          break;
        case "codebarre":
          this.type = codebarre;
          break;
        case "echarts":
          this.type = echarts;
          break;
        default:
          this.type = notype;
          break;
      }
    },
  },

  mounted() {
    this.urlSend = !this.modal
      ? this.$route.path
      : this.$store.getters.modalShowParams({ type: "to", val: this.page }).url;

    this.args =
      this.modal &&
      this.$store.getters.modalShowParams({ type: "to", val: this.page }).show
        ? this.$store.getters.modalShowParams({ type: "to", val: this.page })
            .params
        : this.$route.params;
    this.getModuleFromAPI();

    this.API({ type: "post", url: "engine/entreprises/list", params: {} }).then(
      (resp) => {
        this.listEntreprises = resp.data;
      }
    );
  },
};
</script>
