<template>

    <div>

        <div v-if="datas.initfile">
            <v-tabs v-model="tab">

                    <v-tabs-slider></v-tabs-slider>

                    <v-tab>SCAN</v-tab> 
                    <v-tab>Fichier</v-tab>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-text-field
                        v-model="barcode"
                        label="Scannez un code-barre"
                        @keyup.enter="onBarcodeScanned"
                        />                       
                    </v-tab-item>
                    <v-tab-item>
                        <v-alert
                            border="top"
                            color="orange lighten-2"
                            dark
                            >
                            {{datas.mentionforfile}}
                            </v-alert>
                        <v-file-input
                        label="Importer un fichier"
                        v-model="chosenFile"
                        required
                        accept=".csv,.xlsx"
                        outlined
                        filled
                        clearable
                        class="ma-0 pa-0"
                        ></v-file-input>     
                        <v-btn @click="uploadFile" small class="text-center">Envoyer le fichier CSV</v-btn>                       
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </div>

        <v-text-field
        v-else
          v-model="barcode"
          label="Scannez un code-barre"
          @keyup.enter="onBarcodeScanned"
        />
        </div>
    
    </template>
    
    
    
    <script>

        export default {
        props: ['idmodule','page','modal','parent'],
    
            data: function() {
    
                return {
                    AllowedDisplay : true,
                    datas : [],
                    params:{},
                    querys: {},
                    tab : '',
                    chosenFile : null,
                    barcode: '',
                }
            },

        computed : {

        },

        watch : {

        },

            methods :  {    
                callAPI : function() {
                    this.API({type:'post', url:'displaymodule', params:{id:this.idmodule,params:this.params,querys:this.querys,barcode:this.barcode}}).then((resp) => {

                        this.manageDatas(resp.data)
                            
                    })
    
                },  

                manageDatas(data) {

                    if(data.ventilation) {

                        this.checkScenario({ ventilation: [data], page: this.page, row: 0 })
                        this.barcode = '';
                        return;
                    }

                    if(!data.success) {
                        this.AllowedDisplay = false; 
                        return false;
                    }

                    this.datas = data;
                },

                uploadFile : function() {
                    if(!this.chosenFile) {
                        this.$toast.warning('Veuillez sélectionner un fichier avant d\'importer')
                        return false;
                    }
                    this.$store.dispatch('textOverlay','Importation en cours');
                    this.$store.dispatch('getoverlay', true)
                    const formData = new FormData();
                    formData.append('importFile', this.chosenFile);
                    formData.append('id', this.idmodule);

                    this.API({type:'post', url:'displaymodule', params:formData, apidefault:{headers:{'Content-Type':'multipart/form-data'}}}).then((resp) => {
                        this.$store.dispatch('getoverlay', false)
                            if(!resp.data.success) {
                            this.$toast.warning(resp.data.message) 
                            return false;
                            }
                            if(resp.data.waituser) {
                                if(confirm(resp.data.waituser)) {
                                    this.$store.dispatch('getoverlay', true)
                                    formData.append('allowInsertion', 1);
                                    this.API({type:'post', url:'displaymodule', params:formData, apidefault:{headers:{'Content-Type':'multipart/form-data'}}}).then((resp2) => {
                                        this.chosenFile = null
                                        this.$store.dispatch('getoverlay', false)
                                        if(!resp2.data.success) {
                                        this.$toast.warning(resp2.data.message) 
                                        return false;
                                        }   
                                        this.$toast.success(resp2.data.message)
                                    })
                                }
                            }

                    })
    
                },  


                onBarcodeScanned() {
                    this.callAPI()
                }
                
            },
    
            created() {  

            },

            mounted() {
                this.params = this.$store.getters.specificPage(this.page).params    
                this.querys = this.$store.getters.specificPage(this.page).querys

                let getdatas = this.$store.getters.getModuleDatas({page:this.page,id:this.idmodule});
                if(getdatas) {
                    this.manageDatas(getdatas.datas)
                    this.$store.dispatch('removeModuleDatas',{page:this.page,id:this.idmodule})
                } else {
                    this.callAPI() 
                } 
            }
    
        }
    
    </script>
    