<template>
  <v-card>
    <v-row>
      <v-col class="ma-1">
        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Paramètres généraux</v-tab>
          <v-tab> Liste des requetes </v-tab>
          <v-tab> Modules de recherche </v-tab>
          <v-tab> Actions </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">
            <v-tab-item>
              
              <Select
                :item="{name: 'Quel type de Chart ?',value: 'type',liste: listTypeCharts,
                defaultprops:{ show: true}}"
                :change="(item,value) => { displays.params.generalparams.type = value['value']; }"
                :valField="displays.params.generalparams.type"
                />

            </v-tab-item>

            <v-tab-item>

              <v-btn fab small @click="addListe"><v-icon>mdi-plus</v-icon></v-btn>
            <v-expansion-panels variant="popout" v-model="listQuerysAccordion" class="pa-3">
              <v-expansion-panel v-for="(query, i) in displays.params.listquerys" :key="'lf'+i" v-show="query.key!='default'">
                <v-expansion-panel-header>
                  {{query.default}}
                  <template v-slot:actions>
                    <v-btn icon @click="removeListe(i)" v-if="query.key!='default'"><v-icon>mdi-close-thick</v-icon></v-btn>
                  </template> 
                </v-expansion-panel-header>

                <v-expansion-panel-content class="grey lighten-3">

                  <div>
                    <TextField                  
                    :item="{name:'clé',value:'key',defaultprops:{show:true}}" 
                    :keyup="(item,value) => {query.key = value } "
                    :valField="query.key"/>
                    <Select
                      :item="{name: 'table ciblée',value: 'table',liste: listTables,
                      defaultprops:{ show: true,clearable:true } } "
                      :change="(item,value) => { query.table = value['value'] || ''; } "
                      :valField="query.table"
                      />               
                  </div>

                  <v-tabs v-model="tabsFiche" v-if="query.table && query.table!=''">
                    <v-tab> Filtre </v-tab>
                    <v-tab> Champs </v-tab>
                    <v-tabs-items v-model="tabsFiche">
                      <v-tab-item>
                        <Filtre :page="page" :filtre="query.filtre" :table="query.table" />
                      </v-tab-item>
                      <v-tab-item> 
                        <Fields :page="page" :champs="query.champs" :filter="query.filtre" :table="query.table" />
                        </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>

                </v-expansion-panel-content>  

              </v-expansion-panel>
            </v-expansion-panels>

            </v-tab-item>

            <v-tab-item>
              <!-- Modules de recherche -->
              <div class="text-h6">
                Si le ou les modules sélectionnés se trouvent sur la même page,
                ils interagieront avec le tableau
              </div>

              <SimpleTable
                :Headers="[
                  { text: 'ID', value: 'id' },
                  { text: 'champs', value: 'champs' },
                ]"
                :datas="dataTableSearch"
                :selected="displays.params.searchmodule"
                keyRow="id"
                @getselected="getselected"
              />

              <div class="text-h6">
                champs selectionnés pour la recherche globale
              </div>
              <Autocomplete
                :item="{
                  name: 'Champs ciblés pour recherche globale',
                  value: 'globalsearch',
                  defaultprops: { show: true, multiple: true },
                  liste: listFields,
                }"
                :valField="displays.params.globalsearch"
                :change="changeGlobalSearch"
              />
            </v-tab-item>

            <v-tab-item>
              <listActions :page="page" :module="module" :key="reload" />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "TableModule",
  props: ["module", "page"],

  components: {
    Autocomplete: () => import("../../fields/autocomplete.vue"),
    TextField: () => import("../../fields/textfield.vue"),
    SimpleTable: () => import("../outils/simpleTable.vue"),
    Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
    Fields: () => import("../outils/constructField/Field.vue"),
    //SwitchC: () => import("../../fields/switch.vue"),
    Select: () => import("../../fields/select.vue"),
    listActions: () => import("../outils/listActions.vue"),
    //ArrayTable: () => import("../../fields/arrayTable.vue"),
  },

  data: function () {
    return {
      tab: null,
      displays: this.module.displays,
      dataTableSearch: [],
      listFields: [],
      reload : 0,
      listQuerysAccordion : '',
      tabsFiche: '',
      listTables : [],
      listTypeCharts : [{text:'test',value:'test'},{text:'Pie',value:'pie'},{text:'Bar',value:'bar'},{text:'Line',value:'line'},{text:'bar-horizontal',value:'bar-horizontal'}],
    };
  },

  watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false) {
          this.reload++;
        }
      },
      deep: true,
    },
  },

  methods: {
    getsearchFromAPI() {
      this.API({
        type: "post",
        url: "/engine/module/list",
        params: { type: "search" },
      }).then((resp) => {
        resp.data.forEach((v) => {
          this.dataTableSearch.push({
            id: v.id,
            champs: v.displays.params.champs.join(", "),
          });
        });
        // pour intégrer la recherche globale :
        this.dataTableSearch.push({
          id: "global",
          champs: "sensible au champ de la recherche globale",
        });
      });
    },

    //permet de récupérer les champs issues de la requete pur les insérer dans la recherche globale
    getFields() {
      this.API({
        type: "post",
        url: "engine/module/getfieldswithjunctions",
        params: { table: this.module.table, filter: this.displays.filter },
      }).then((resp) => {
        this.Fields = resp.data.fields;
        resp.data.fields.forEach((field) => {
          this.listFields.push({
            text: field.table + "." + field.field,
            value: field.cle,
          });
          if (field.table == this.module.table)
            this.FieldsOfModuleTable.push({
              text: field.table + "." + field.field,
              value: field.cle,
              default: "value",
            });
        });
      });
    },

    addListe() {
    
    var Default = this.displays.params.listquerys.find((el) => el.key ==='default')
    let copieDefault = JSON.parse(JSON.stringify(Default))

    copieDefault.key="newquery"
    this.displays.params.listquerys.push(copieDefault);
  },


    changeGlobalSearch(item, value, selectedKey) {
      this.displays.params.globalsearch = [];
      for (var selected in value) {
        if (typeof value[selected] == "object") {
          this.displays.params.globalsearch.push(value[selected][selectedKey]);
        } else {
          this.displays.params.globalsearch.push(value[selected]);
        }
      }
      this.$emit("recepVal", this.displays);
    },

    getselected(val) {
      this.displays.params.searchmodule = val;
      this.$emit("recepVal", this.displays);
    },

  },

  mounted() {
    this.getsearchFromAPI();
    this.API({type:'get', url:'engine/getlisttable'}).then((resp) => {
                    resp.data.forEach((table) => {
                        this.listTables.push({text:table,value:table})
                    })
                    
                })
    //this.getFields();
  },
};
</script>
