// permet d'appeler des fonctions directement dans les componsants sans faire d'import de fichier.
// ces composants sont appelés this this.xxxx
// exemple : this.$api.get ...

import store from "../store/store";
import moment from "moment";
import router from "./customRouter.js";
import main from "../components/main/templates/mainTemplate";
import localization from 'moment/locale/fr';


import textfield from '../components/fields/textfield'
import select from '../components/fields/select'
import radio from '../components/fields/radio'
import SwitchC from '../components/fields/switch'
import colorpicker from '../components/fields/colorPicker'
import arraytable from '../components/fields/arrayTable'
import autocomplete from '../components/fields/autocomplete'
import datepicker from '../components/fields/datePicker'
import datetimepicker from '../components/fields/dateTimePicker'
import textarea from '../components/fields/textarea'
import fileinput from '../components/fields/fileInput'
import texteditor from '../components/fields/texteditor'
import encartphoto from '../components/fields/encartPhoto'

import { EventBus } from './EventBus.js';



const functions = {
    install(Vue) {
        Vue.prototype.randomKey = (length) => {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        Vue.prototype.moment = (date) => {
            moment.locale('fr', localization)
            return moment(date)
        }

        Vue.prototype.getAllPages = () => {
            return new Promise((resolve) => {
                Vue.prototype.API({ type: 'get', url: 'allpage' }).then((resp) => {
                    resp.data.forEach((element) => {
                        let rout = {
                            path: element.url,
                            component: main,
                            meta: {},
                            name: element.name,
                            children: [],
                            engine: element.engine,
                            table: element.table,
                            parentform: element.parentform,
                            params: element.displays.params,
                            displayname: element.displays.displayname,
                            // obligatoire pour l'envoi dans le component
                            props: {
                                page: element.id,
                                typeform: element.typeform,
                            },
                            //props: (route) => ({ page: element.id, type: element.type, get: route.query }),
                        };

                        if (element.children && element.children.length > 0) {

                            element.children.forEach((child) => {
                                let routchild = {
                                    path: child.url,
                                    component: main,
                                    meta: {},
                                    name: child.name,
                                    engine: child.engine,
                                    table: child.table,
                                    parentform: child.parentform,
                                    params: child.displays.params,
                                    displayname: child.displays.displayname,
                                    props: {
                                        page: child.id,
                                        typeform: child.typeform,

                                    },
                                };
                                router.addRoute(routchild);

                                let forVuex = {
                                    path: child.url,
                                    name: child.name,
                                    displayname: child.displays.displayname,
                                    typeform: child.typeform,
                                    props: routchild.props,
                                    parentform: child.parentform
                                }
                                store.dispatch("pushAll", forVuex);
                            })
                        }

                        router.addRoute(rout);

                        let forVuex = {
                            path: element.url,
                            name: element.name,
                            displayname: element.displays.displayname,
                            typeform: element.typeform,
                            props: rout.props,
                            parentform: element.parentform
                        }
                        store.dispatch("pushAll", forVuex);
                        resolve(true);
                    });
                });
            });
        };

        // permet de spliter l'url de base(client/:id) et de le remplacer par l'id que l'on a placé dans dans l'élément replace de la fonction gotTo
        Vue.prototype.newurl = (elem) => {
            // on recherche la page avec l'id en question
            let targetPage = JSON.parse(JSON.stringify(store.getters["listAll"]))

            targetPage = targetPage.find(
                (el) => {
                    return (
                            (el.props.page !== undefined && el.props.page === elem.page) ||
                            (elem.name !== undefined && el.name === elem.name) ||
                            (elem.url !== undefined && el.path === elem.url)
                        ) &&
                        (elem.typeform === undefined || el.props.typeform === elem.typeform)
                }
            ) || false;
            if (targetPage) {
                var ret = '';
                switch (elem.returnElement) {
                    case 'url':
                        ret = targetPage.path.replace(/:([^/]+)/g, (_, key) => elem.replace[key])
                        break;
                    case 'all':
                        targetPage.originalPath = targetPage.path
                        targetPage.path = targetPage.path.replace(/:([^/]+)/g, (_, key) => elem.replace[key])
                        ret = targetPage;
                        break;
                    default:
                        ret = targetPage.path.replace(/:([^/]+)/g, (_, key) => elem.replace[key])
                }
                return ret;

            } else { return false; }

        };


        // raccourcie pour appeler une page ( soit en modal, soit en page centrale)
        // variables pour elements :
        // page : id de la page qui va etre appelé - (numerique)
        // replace : arguments de l'url, exemple : {id: [id_du_client]} - (array)
        // type : modal ou center - (string)
        // rootPage : page ou se trouve la fonction goTo - (numerique)
        // paramDialog : paramètres du modal, voir dans templates - modalTemplate avec la var paramDialog - (array)
        Vue.prototype.goTo = (elements) => {
            elements.returnElement = 'all'
            var newurl = Vue.prototype.newurl(elements);
            // on check si les params sont bien définis, sinon on arrete l'envoi de le page
            if (!newurl.path) {
                Vue.$toast.error('Paramètres manquants, vous ne pouvez pas accéder à cette page', { position: "top-right", timeout: 3000 });
                return false;
            }

            //si on veut juste appliquer une query sans faire appel a la page.
            if (elements.query && elements.reload) {
                if (!elements.modal) {
                    router.replace({ query: elements.query });
                } else {
                    store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: 'query', value: elements.query });
                }
                return
            }


            if (elements.type == "modal") {

                store.dispatch("showModal", {
                    newurl: newurl.path,
                    propsPage: newurl,
                    from: elements.rootPage,
                    params: elements.replace,
                    query: elements.query,
                    paramDialog: elements.paramDialog,
                });
            } else if (elements.type == "center") {

                router.push({ name: elements.page, params: elements.replace, query: elements.query });
            } else {
                alert(
                    "mauvais parametrage de direction : aucun type d'ouverture selectionné"
                );
            }
        };


        // envoie vers l'api en intégrant le queue
        Vue.prototype.API = (elements) => {
            return new Promise((resolve) => {
                var params = (!elements.params) ? {} : elements.params;
                store.dispatch("callAxios", { token: Vue.prototype.randomKey(6), type: elements.type, url: elements.url, params: params, apidefault: elements.apidefault }).then((resp) => {
                    resolve(resp)
                });

            })
        }

        Vue.prototype.checkScenario = (elements) => {

            return new Promise((resolve, reject) => {
                let getResponseKey = (!elements.getResponseKey) ? {} : elements.getResponseKey;
                let el = elements.ventilation[elements.row];
                let fields = (elements.fields) ? elements.fields : [];
                //on récupère les lignes de before et after, ainsi que les potentielles insertions et requetes simple pour lesrelancer ds la backe et les utiliser
                getResponseKey['lines'] = el.result.lines;
                var wait = false;
                var stop = false;
                //chaque returnfront représente une action a envoyé dans le front pour une ventilation
                if (el && el.result) el.result.returnfront.forEach((returnFront) => {
                    if (!wait) {
                        switch (returnFront.type) {
                            case 'ifconfirm':

                                //le setTimeout pour juste laisser le temps à des refresh de module ou autre (en meme temps que le ifconfirm se lance)

                                if (confirm(returnFront.text)) {
                                    getResponseKey[returnFront.key] = false
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page, fields: fields } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkScenario({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row, fields: fields }))

                                    })
                                } else {
                                    getResponseKey[returnFront.key] = true
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page, fields: fields } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkScenario({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row, fields: fields }))

                                    })
                                }

                                wait = true
                                break;

                            case 'chooseuser':


                                getResponseKey[returnFront.key] = true
                                EventBus.$emit('sendDatas', { template: returnFront.template, crochet: '', page: elements.page });

                                EventBus.$on('send-data', (data) => {
                                    EventBus.$off('send-data');
                                    getResponseKey['lines'][returnFront.choicekey] = data;
                                    Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page, fields: fields } }).then((resp) => {
                                        elements.ventilation[elements.row].result = resp.data.result
                                        resolve(Vue.prototype.checkScenario({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row, fields: fields }))

                                    })
                                });
                                wait = true;
                                break;
                            case 'alert':

                                Vue.$toast[returnFront.state](returnFront.text);

                                break;

                                //permet de renvoyer le fait que la requete a bein été effectué, elle ne se lancera plus par la suite.

                            case 'stop':

                                reject();
                                stop = true;
                                break;

                            case 'overlay':
                                getResponseKey[returnFront.key] = true
                                store.dispatch('textOverlay', returnFront.text)
                                store.dispatch('getoverlay', returnFront.isopen);

                                Vue.prototype.API({ type: 'post', url: 'displaymodule', params: { id: el.id, params: el.params, response: getResponseKey, page: el.page, fields: fields } }).then((resp) => {
                                    elements.ventilation[elements.row].result = resp.data.result
                                    resolve(Vue.prototype.checkScenario({ ventilation: elements.ventilation, page: elements.page, getResponseKey: getResponseKey, row: elements.row, fields: fields }))

                                })
                                wait = true;


                                break;

                            case 'reloadmodule':
                                store.dispatch('addReloadModule', { modules: returnFront.module })
                                break;

                            case 'closecurrmodal':
                                store.dispatch('closeModal', { page: store.getters.modalShowParams({ type: 'to', val: elements.page }).from })
                                break;

                            case 'loadmodule':
                                store.dispatch('addVentilation', { type: 'loadmodule', module: returnFront.module, crochet: returnFront.crochet, datas: returnFront.datas, page: elements.page })
                                break;

                            case 'changestore':
                                returnFront.fields.forEach((field) => {

                                    store.dispatch('pushForm', { page: elements.page, value: field.value, champ: field.field })
                                })

                                break;

                            default:
                                //permet de renvoyer le fait que la requete a bein été effectué, elle ne se lancera plus par la suite.
                                getResponseKey[returnFront.key] = returnFront.type;

                        }
                    }

                })

                if (stop) {
                    reject();
                    return;
                } else {
                    if (!wait) {

                        if (elements.ventilation[elements.row + 1]) {

                            resolve(Vue.prototype.checkScenario({ ventilation: elements.ventilation, page: elements.page, getResponseKey: {}, row: elements.row + 1, fields: fields }))
                        } else {

                            resolve();
                        }
                    }
                }

            });

        }





        Vue.prototype.validateForm = (elements) => {
            //action de validation du formulaire
            let modal = store.getters.modalShowParams({ type: "to", val: elements.page, });
            //récup des éléments du form
            let elemForm = store.getters.listForm(elements.page)


            // on récupère l'url pour le lancement du put vers l'API (l'url du formulaire est similaire à l'url de modification du form)
            var urlSend = elemForm.url
                //si ca vient d'un formulaire indépendant :
            urlSend = (elements.urlSend) ? elements.urlSend : urlSend;

            store.dispatch('getoverlay', true)

            if (elements.form && elements.form.validate()) {
                // validation du formulaire  

                // on récupère le type d'enregistrement :
                let getTypeValidate = (!elements.validate && elemForm) ? elemForm.validate : elements.validate;

                // permet de cibler les champs qui sont updatés : soit les champs classiques via le store, 
                //soit les champs issues d'un form indépendant (via le FormModule)
                let valFields = (!elements.fields) ? elemForm.fields : elements.fields;


                //première étape, envoi pour vérification des ventilations :
                //Lancement des ventilations pour vérification avant enregistrement. 
                console.log(valFields)

                //début d'enregistrement du formulaire
                Vue.prototype.API({ type: 'put', url: urlSend, params: { fields: valFields, ventilation: elements.ventilation } }).then((resp) => {

                        store.dispatch('getoverlay', false)

                        if (resp.data.success) {
                            //si l'enregistrement détecte des ventilations avant l'action, la function ventil prend la relève. dès que cette function est trerminé et retourne true, 
                            // on repart sur un nouvel enregistrement, en mettant une var dans l'url pour ne plus lancer les ventils. 
                            if (resp.data.ventilation && resp.data.ventilation.before) {
                                store.dispatch('getoverlay', true)
                                Vue.prototype.checkScenario({ ventilation: resp.data.ventilation.before, page: elements.page, row: 0, fields: valFields }).then(() => {
                                    store.dispatch('getoverlay', false);
                                    elements.ventilation = true;

                                    return Vue.prototype.validateForm(elements)
                                }).catch(() => {
                                    store.dispatch('getoverlay', false);
                                    elements.ifNotValid()
                                    return false;
                                })
                                return;
                            }

                            if (resp.data.isStored) Vue.$toast.success('enregistré', { position: "bottom-right", timeout: 2000 });

                            //permet de stocker la dernière insertion ou le dernier update
                            store.dispatch('setLastStored', { page: elements.page, fields: resp.data.args });

                            //si l'enreg est en mode classique
                            if (!elements.fields) {
                                //si c'est un enregistrement frais
                                if (resp.data.new) {

                                    // si c'est une modal
                                    if (modal && modal.show == true) {

                                        switch (getTypeValidate) {

                                            case 1:
                                                //validation qui entraine directement vers le mode édition de cette ligne

                                                //1 on change dans les params le id=new par id=nouvel id de la ligne
                                                elemForm.params.id = resp.data.args.id
                                                elemForm.typeform = 'edit'
                                                    //1 - on change l'url de destination pour mettre la nouvelle                                   
                                                    // si c'est une modal est que l'enregistrement se fait sur un enfant, il faut aussi mettre à jour idparent
                                                    //store.dispatch('changeParamForm', { page: elements.page, type: 'params', value: resp.data.args });                                              
                                                urlSend = Vue.prototype.newurl({ name: resp.data.redirect, replace: elemForm.params });
                                                elemForm.url = urlSend
                                                modal.params = elemForm.params
                                                modal.propsPage.name = resp.data.redirect
                                                modal.propsPage.path = urlSend
                                                modal.propsPage.url = urlSend
                                                store.dispatch('changeParamPage', { page: this.page, item: 'params', value: elemForm.params })
                                                break;

                                            case 2:
                                                //validation qui entraine un nouveau formulaire
                                                //appel de l'api pour récupérer les valeurs des champs
                                                // les params permettent de récupérer des POST à envoyer dans l'API afin de préremplir les champs l'ors de l'insert
                                                Vue.prototype.API({ type: 'get', url: urlSend, params: elemForm.params }).then((resp) => {
                                                    // on réinitialise le store du formulaire
                                                    store.dispatch('initForm', { page: elements.page, valid: true });
                                                    for (const array in resp.data.valFields) {
                                                        store.dispatch('pushForm', { page: elements.page, champ: array, value: resp.data.valFields[array] });
                                                    }

                                                })
                                                break;

                                            case 3:
                                                //validation qui ferme de suite la modale apres
                                                store.dispatch('closeModal', { page: store.getters.modalShowParams({ type: 'to', val: elements.page }).from })
                                                break;
                                        }

                                    } else {
                                        //si enreg frais et pas modal
                                        switch (getTypeValidate) {

                                            case 1:
                                                //validation qui entraine directement vers le mode édition de cette ligne
                                                Vue.prototype.goTo({ rootPage: elements.page, name: resp.data.redirect, type: 'center', replace: resp.data.args })
                                                break;

                                            case 2:
                                                //validation qui entraine un nouveau formulaire
                                                router.go()
                                                break;

                                            case 3:
                                                //validation qui ferme de suite la modale apres
                                                //si ca ferme automatiquement, on reviens à le précédente page
                                                router.go(-1)
                                                break;
                                        }
                                        // si c'est une page centrale, et nouveau form on rafraichi tout simplement


                                    }

                                } else {
                                    //si le formulaire est déjà en mode édition'
                                    if (modal && modal.show == true) {
                                        //et que c'est une modal

                                        if (getTypeValidate == 3) {
                                            // si la validation entraine la fermeture automatique de la modal
                                            store.dispatch('closeModal', { page: store.getters.modalShowParams({ type: 'to', val: elements.page }).from })
                                        }
                                    } else {
                                        //si ce n'est pas un modal 
                                        if (getTypeValidate == 3) {
                                            //si ca ferme automatiquement, on reviens à le précédente page
                                            router.go(1)
                                        }
                                    }



                                }


                            } // fin enreg en mode classique (via le store). si on veut rajouter des actions après enreg en mode indépendant,
                            // mettre un else à  ce niveau la 

                            //lancement des ventilations (si il y a) après enregistrement 
                            if (resp.data.ventilation && resp.data.ventilation.after) {
                                Vue.prototype.checkScenario({ ventilation: resp.data.ventilation.after, page: elements.page, row: 0, fields: valFields }).catch(() => {})
                            }

                            elements.callback(resp.data);

                        } else {
                            Vue.$toast.error(resp.data.message, { position: "bottom-right", timeout: 2000 });
                            elements.ifNotValid()
                        }

                    })
                    //fin d'enregistrement du formulaire




            } else {
                store.dispatch('getoverlay', false)
                elements.ifNotValid()
            }

        }



        Vue.prototype.clickActions = (elements) => {
            // args : éléments de la ligne sélectionné provenants de la base, envoyé par le module.
            // action : array du l'action comportant les arguments de l'action
            //idmodule : id du module ou se trouve l'action
            //page : la page d'ou vient l'action
            //selectedLines : si c'est du module table, cela peut interagir avec plusieurs lignes. dans ce cas c'est true
            var params = {}
            var query = {}

            if (elements.action.typelink == 'link' || elements.action.typelink == 'api') {
                //params['line'] = elements.args
                elements.action.get.forEach((v) => {

                    if (v.typedata == 'array') {
                        params[v.key] = {}
                        v.value.forEach((av) => {
                            params[v.key][av.key] = Vue.prototype.replaceBy({ text: av.value, page: elements.page });
                            if (av.type == 'table') params[v.key][av.key] = elements.args[av.value]
                        })
                    } else {
                        if (v.typevar == "query") {
                            query[v.key] = Vue.prototype.replaceBy({ text: v.value, page: elements.page });
                            if (v.type == 'table') query[v.key] = elements.args[v.value]
                        } else {
                            params[v.key] = Vue.prototype.replaceBy({ text: v.value, page: elements.page });
                            if (v.type == 'table') params[v.key] = elements.args[v.value]
                        }
                    }

                })

            }

            if (elements.action.typelink == 'link') {
                Vue.prototype.goTo({ rootPage: elements.page, name: elements.action.page_name, type: elements.action.target, replace: params, query: query, paramDialog: elements.action.paramdialog })
                if (elements.whenlink) elements.whenlink()
            }

            if (elements.action.typelink == 'api') {
                let act = elements.action.params
                params['action'] = elements.action.id;
                params['selected'] = elements.selectedLines;
                if ((act.ifconfirm && act.ifconfirm != '' && confirm(act.ifconfirm)) || (!act.ifconfirm || act.ifconfirm == '')) {
                    store.dispatch('textOverlay', 'Action en cours')
                    store.dispatch('getoverlay', true);
                    Vue.prototype.API({ type: act.apilink.type, url: Vue.prototype.replaceBy({ text: act.apilink.url, page: elements.page, line: elements.args }), params: params }).then((resp) => {
                    store.dispatch('getoverlay', false);    
                        if (resp.data.ventilation) {
                            // si il ya une ventil avant modif d'un champ
                            if (resp.data.ventilation.before) {
                                Vue.prototype.checkScenario({ ventilation: resp.data.ventilation.before, page: elements.page, row: 0 }).then(() => {

                                    }).catch(() => {
                                        return;
                                    })
                                    // si il ya une ventil après modif d'un champ
                            } else if (resp.data.ventilation.after) {
                                Vue.prototype.checkScenario({ ventilation: resp.data.ventilation.after, page: elements.page, row: 0 })
                            } else {
                                // si on appelle directement un module de ventilation
                                Vue.prototype.checkScenario({ ventilation: [resp.data], page: elements.page, row: 0 }).catch(() => { return; })
                            }

                        }

                        if (resp.data.success) {
                            elements.success(resp.data)
                        } else {
                            if (resp.data.message) Vue.$toast.error(resp.data.message);
                        }
                    });

                }

            }

            if (elements.action.typelink == 'href') {
                if (Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }) &&
                    Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }) != 'null') {
                    window.open(Vue.prototype.replaceBy({ text: elements.action.params.apilink.url, page: elements.page, line: elements.args }), '_blank');
                } else {
                    Vue.$toast.error('Lien non inséré')
                }

            }

        }


        //permet de cibler quel modale va etre affiché : serv essentiellement dans templates/page/elements/module.vue
        Vue.prototype.getComponent = (elements) => {
            var component = "notfound-component";
            switch (elements.component) {
                case "table":
                    component = "table-component";
                    break;
                case "search":
                    component = "search-component";
                    break;
                case "form":
                    component = "form-component";
                    break;
                case "calendar":
                    component = "calendar-component";
                    break;
                case "tabs":
                    component = "tabs-component";
                    break;
                case "pdf":
                    component = "pdf-component";
                    break;
                case "bibliotheque":
                    component = "bibli-component";
                    break;
                case "sendemail":
                    component = "sendemail-component";
                    break;
                case "historiqueemail":
                    component = "historiqueemail-component";
                    break;
                case "frontdatas":
                    component = "frontdatas-component";
                    break;
                case "ged":
                    component = "ged-component";
                    break;
                case "ventilation":
                    component = "ventilation-component";
                    break;
                case "planning":
                    component = "planning-component";
                    break;
                case "codebarre":
                    component = "codebarre-component";
                    break;
                case "echarts":
                    component = "echarts-component";
                    break;
                default:
                    component = "notfound-component";

            }
            return component;
        };

        //permet d'afficher dans les textes des éléments dynamiques : [param_target||nom_param]
        Vue.prototype.replaceBy = (elements) => {
            // le commentaire juste en dessous permet de supprimer une erreur js, donc à ne pas supprimer
            /* eslint-disable no-useless-escape */
            if (!elements.text) return false;
            var str = elements.text.match(/(?<=\[\[)([^\[\]]*)(?=\]\])/gim);
            var returnText = elements.text;
            if (Array.isArray(str))
                str.forEach((match) => {
                    if (match.indexOf("||") > 0) {
                        var form = store.getters.listForm(elements.page);
                        var page = store.getters.specificPage(elements.page);
                        var modal = store.getters.modalShowParams({
                            type: "to",
                            val: elements.page,
                        });

                        var def = match.split("||");

                        var deep = def[1].split(' ')
                        switch (def[0]) {
                            case "form":
                                if (form && form.fields) {
                                    var findInArray = form
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );

                                }
                                break;
                            case "user":

                                findInArray = store.getters.currentUser
                                deep.forEach((el) => {
                                    findInArray = findInArray[el]
                                })
                                findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    findInArray
                                );
                                break;
                            case "frontdatas":
                                findInArray = store.getters.getFrontDatas
                                if (elements.datas) findInArray = elements.datas;
                                deep.forEach((el) => {
                                    findInArray = (findInArray[el]) ? findInArray[el] : ""
                                })
                                findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    findInArray
                                );
                                break;

                            case "page":
                                if (page) {
                                    findInArray = page
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                }
                                break;
                            case "routerparam": // params dans le back
                                // si un customparam est paramétré on le prend, sinon c'est le param de la page"
                                var datas = (elements.datas) ? elements.datas : page.params;
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    datas[def[1]]
                                );
                                /*
                                    if (modal && modal.show == true) {
                                        returnText = returnText.replace(
                                            "[[" + match + "]]",
                                            modal.params[def[1]]
                                        );
                                    } else {
                                        returnText = returnText.replace(
                                            "[[" + match + "]]",
                                            router.history.current.params[def[1]]
                                        );
                                    } */
                                break;

                            case "routerquery":
                                if (modal && modal.show == true) {
                                    findInArray = (typeof modal.query[def[1]] === 'undefined' || !modal.query) ? "" : modal.query[def[1]];
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                } else {
                                    findInArray = (typeof router.history.current.query[def[1]] === 'undefined') ? "" : router.history.current.query[def[1]];
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );

                                }

                                break;

                            case "table":
                                if (elements.line) {
                                    findInArray = elements.line
                                    deep.forEach((el) => {
                                        findInArray = findInArray[el]
                                    })
                                    findInArray = (typeof findInArray === 'undefined') ? "" : findInArray;
                                    returnText = returnText.replace(
                                        "[[" + match + "]]",
                                        findInArray
                                    );
                                }
                                break;

                            case 'date':
                                //deep étant la valeur récupéré de la variable
                                //pour la date [[date||date ou timestamp]]
                                var date = "";
                                switch (deep[0]) {
                                    case 'date':
                                        date = Vue.prototype.moment().format('YYYY-MM-DD')
                                        break;

                                    case 'datetime':
                                        date = Vue.prototype.moment().format("YYYY-MM-DD HH:mm:ss")
                                        break;
                                    default:
                                        date = Vue.prototype.moment().format(deep[0])
                                        break;
                                }
                                returnText = returnText.replace(
                                    "[[" + match + "]]",
                                    date
                                );


                                break;
                        }
                    }
                });

            return returnText;
        };

        Vue.prototype.createNewPage = (elements) => {
            Vue.prototype.API({ type: 'post', url: '/newpage', params: elements }).then((resp) => {
                resp.data.forEach((page) => {

                    let rout = {
                        path: page.url,
                        component: main,
                        name: page.name,
                        displayname: page.displays.displayname,
                        children: page.children,
                        engine: page.engine,
                        table: page.table,
                        parentform: page.parentform,
                        params: page.displays.params,
                        // obligatoire pour l'envoi dans le component
                        props: {
                            page: page.id,
                            type: page.type,

                        },
                    };

                    if (page.id) {
                        router.addRoute(rout);
                        delete rout.component;
                        store.dispatch("pushAll", rout);
                        //router.push({ name: resp.data.name });
                    }

                })
                elements.callback(resp.data);

            });
        };
        // permet de changer un store à partir du paramétrage otherparams du champ
        Vue.prototype.changeStoreOnField = (elements) => {

            if (!elements.item.otherparams || !Array.isArray(elements.item.otherparams.store) || elements.item.otherparams.store.length == 0) return false;

            elements.item.otherparams.store.forEach((storeParam) => {
                const keys = storeParam.listKeys.split(' ')

                switch (storeParam.type) {

                    case 'user':
                        store.dispatch('updateUser', { keys: keys, value: elements.model })

                        break;
                    case 'form':
                        if (keys.length == 2) {
                            store.dispatch('pushForm', { champ: keys[0], key: keys[1], value: elements.model, page: elements.page })
                        } else {
                            store.dispatch('pushForm', { champ: keys[0], value: elements.model, page: elements.page })
                        }
                        break;
                    case 'page':
                        if (keys.length == 2) {

                            store.dispatch('changeParamPage', { page: elements.page, item: keys[0], key: keys[1], value: elements.model })
                        } else {
                            store.dispatch('changeParamPage', { page: elements.page, item: keys[0], value: elements.model })
                        }

                        break;
                    case 'modal':

                        if (keys.length == 2) {
                            store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: keys[0], key: keys[1], value: elements.model })
                        } else {
                            store.dispatch('changeOptionsModal', { modal: { type: 'to', val: elements.page }, type: keys[0], value: elements.model })
                        }
                        break;
                }
            })


        }

        //permet de selectionner le composant field adéquate (sert dans arrayTable.vue, RoutingField, et calendarModule)
        Vue.prototype.getFieldComponent = (format) => {

            let component = textfield
            switch (format) {
                case 'textfield':
                case 'number':
                case 'email':
                    component = textfield
                    break;
                case 'select':
                    component = select
                    break;
                case 'radio':
                    component = radio
                    break;
                case 'switch':
                    component = SwitchC
                    break;
                case 'colorpicker':
                    component = colorpicker
                    break;
                case 'arraytable':
                    component = arraytable
                    break;
                case 'autocomplete':
                    component = autocomplete
                    break;
                case 'datepicker':
                    component = datepicker
                    break;
                case 'datetimepicker':
                    component = datetimepicker
                    break;
                case 'textarea':
                    component = textarea
                    break;
                case 'fileinput':
                    component = fileinput
                    break;
                case 'texteditor':
                    component = texteditor
                    break;
                case 'encartphoto':
                    component = encartphoto
                    break;
                default:
                    component = textfield
                    break;
            }

            return component;
        }



        //permet de retourner un array correspondant au résultat les conditions de visuel pour les éléments de page
        // et le template.
        Vue.prototype.launchConditions = (condParams) => {
            let arrayCond = [];
            if (!condParams || !Array.isArray(condParams)) return [];
            condParams.forEach((cond) => {


                switch (cond.type) {

                    case 'groupcond':

                        var returnCond = Vue.prototype.visuelConditions(cond.content);
                        //si condition ET et qu'une des condition n'est pas remplis on retourn false
                        if (cond.andor == 'and') {
                            if (returnCond.includes(false) || !returnCond.length) { arrayCond.push(false) } else { arrayCond.push(true); }
                        }
                        //si condition OU est qu'on ne trouve pas de condition remplis on retourne false
                        if (cond.andor == 'or') {
                            if (!returnCond.includes(true) || !returnCond.length) { arrayCond.push(false) } else { arrayCond.push(true); }
                        }
                        break;


                    case 'value':
                        var variable = Vue.prototype.replaceBy({ text: cond.variable, page: this.page })
                        var value = Vue.prototype.replaceBy({ text: cond.value, page: this.page })
                        switch (cond.condition) {
                            case 'value':
                                arrayCond.push(variable === value);
                                break;
                            case 'like':
                                arrayCond.push(variable.indexOf(value) != -1);
                                break;
                            case '!=':
                                arrayCond.push(variable != value);
                                break;
                            case '*':
                                arrayCond.push(variable != '' && variable != 'undefined');
                                break;
                            case 'null':
                                arrayCond.push(variable == 'null');
                                break;
                            case 'istrue':
                                arrayCond.push(variable === true);
                                break;
                            case 'isfalse':
                                arrayCond.push(variable === false);
                                break;
                            case 'issup':
                                if (!isNaN(parseInt(variable)) && !isNaN(parseInt(value))) {
                                    arrayCond.push(parseInt(variable) > parseInt(value));
                                }
                                break;
                            case 'isinf':
                                if (!isNaN(parseInt(variable)) && !isNaN(parseInt(value))) {
                                    arrayCond.push(parseInt(variable) < parseInt(value));
                                }
                                break;
                            case 'isequal':
                                arrayCond.push(variable == value);
                                break;
                            default:
                                arrayCond.push(false);
                        }
                        break;
                }

            })
            return arrayCond;
        }



    },
};

export default functions;