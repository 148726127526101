<template>
    <v-card elevation="2" shaped class="ma-1">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Paramètres de la bibliothèque </v-toolbar-title>
      </v-toolbar>
      
      <div>

        <v-tabs align="start" background-color="blue lighten-5" v-model="tab">
          <v-tab> Aide </v-tab>
          <v-tab> Paramètres généraux </v-tab>
          <v-tab> requetes </v-tab>
          <v-tab> Champs </v-tab>
          <v-tab> Sur insertion d'image </v-tab>
          <v-tab> Actions </v-tab>
          <v-tabs-items v-model="tab" class="pl-4">
            <v-tab-item>
              <p>La bibliothèque d'image fonctionne avec la table "Bibliotheques" par défaut. vous devez impérativement intégrer et lier cette table avec une autre table de votre système.</p>
              <p>la table bibliotheque ne doit pas être réinterprété dans le AS.</p>
              <p>Si vous souhaitez cibler uniquement la table bibliotheque, veuillez selectionner "Bibliotheque" en tant que table du module. </p>
              <h3>Champs obligatoires :</h3>
              <p>Vous devez affilier plusieurs AS pour le bon fonctionnement à savoir :</p>
              <ul>
                <li>"name" : Permet d'afficher le nom de l'image comme vous l'entendez</li>
                <li>"categorie" : Chaque image insérés appartiennent à une catégorie défini par ce champ. </li>
                <li>"idligne" (obligatoire uniquement si la table du module n'est pas "bibliotheque") : correspond à l'id de la ligne de la table du module. </li>
                <li>Tous les autres champs serviront à faire fonctionner les actions liés à l'image. </li>
              </ul>
              <h3>Les actions :</h3>
              <p>Vous pourrez intégrer des actions sur chaque image en ajoutant des boutons d'action. (veillez à bien intégrer l'icone pour l'affichage)</p>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col class="ma-1">
                  <TextField
                    :item="{ name: 'Catégorie', value: 'categorie' ,defaultprops:{ show: true }}"
                    :valField="displays.params.categorie"
                    :keyup="(item,model,selectedKey)=> { displays.params.categorie = model; $emit('recepVal',displays); }"
                    /> 
                    <Select :item="{
                      name: 'Champ de liaison avec la table bibliotheque', value: 'fieldlink', liste: listFields,
                      defaultprops: { show: true, column: false }
                    }" :change="(item,model) => { displays.params.fieldlink = model['value']}"
                      :valField="displays.params.fieldlink" />
                      Va permettre de cibler le champ du module lié à la table bibliotheque afin d'attribuer l'id de l'image si ele n'est pas encore insérée
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
                Vous devez obligatoirement affilier un champ de la table principale à la table "bibliotheques" pour récupérer les informations de l'image
              <Filtre
                :filtre="displays.filter"
                :table="module.table"
                @recepVal="changeFilter"
              />
            </v-tab-item>

          <v-tab-item>
            <p>Vous devez sélectionner un champ avec comme AS "name" pour que l'image soit nommé dans la bibliothèque.</p>
            <p>Pour intéragir avec les actions du module, veuille à bien insérer les valeurs pour l'interaction</p>
            <Fields
              :table="module.table"
              :champs="displays.params.champs"
              :filter="displays.filter"
              :page="page"
              @recepVal="changeEC"
              />

              <div class="text-h6">champs selectionnés pour la recherche globale</div>
              <Autocomplete :item="{
                name: 'Champs ciblés pour recherche globale',
                value: 'globalsearch',
                defaultprops: { show: true, multiple: true },
                liste: listFields,
              }" :valField="displays.params.globalsearch" :change="changeGlobalSearch" />
              
          </v-tab-item>

          <v-tab-item>
              Lors de l'insertion d'une image, vous pouvez insérer une nouvelle ligne de la table du module afin que celle-ci soit directement lié à cette image.
              <ul>
                <li>Attribuez obligatoirement votre colonne sujet à la liaison de l'image avec <b>la valeur [[image_id]]</b></li>
                <li>Récupérer le nom de l'image uploadé avec <b>la variable [[image_nom]]</b></li>
              </ul>
              <p>Vous pouvez attribuer des variables d'environnement à vos valeurs d'insertion.</p>
              <ArrayTable 
                    :item="{otherparams:{arrayTable:[{type:'',key:'champ',name:'Variable',ArrayValue:listFields},{type:'',key:'value',name:'Valeur',description:'(Vous pouvez insérer des variables d\'environnement)'}]},
                    defaultprops:{show:true,tableDraggable:false,tableDel:true,hideFooter:true,hidesearch:true,addTitle:'Ajouter un champ'}}"
                    :valField="displays.params.oninsert.champs"
                    :change="(item,model) => { displays.params.oninsert.champs = model; }"
                    />
            </v-tab-item>

          <v-tab-item>

            <listActions :page="page" :module="module" :key="reload"/>

            </v-tab-item>

          </v-tabs-items>
        </v-tabs>
    </div>
    </v-card>
  </template>
  
  
  
  <script>
  export default {
    name: "BibliothequeModule",
    props: ["module", "page"],
  
    components: {
      Autocomplete: () => import("../../fields/autocomplete.vue"),
      Filtre: () => import("../outils/constructFiltre/Filtre.vue"),
      Fields: () => import("../outils/constructField/Field.vue"),
      TextField: () => import("../../fields/textfield.vue"),
      listActions: () => import("../outils/listActions.vue"),
      ArrayTable   : () => import('../../fields/arrayTable.vue'),
      Select: () => import("../../fields/select.vue"),

    },
  
    data: function () {
      return {

        displays: this.module.displays,
        typegenerate : '',
        manual : '',
        reload : 0,
        tab : null,
        listFields : []

      };
    },
  
    watch: {
    showMod: {
      // permet de rafraichir quand la modal de la page se ferme
      handler(val) {
        if (val.show === false) {
          this.reload++;
        }

      },
      deep: true,
    },

  },
  
    methods: {

      generate() {
        this.API({type:'post', url:'pdf/generatefile', params:{id:this.module.id, type:this.typegenerate,manual : this.manual}}).then(() => {

                    })
      },

      changeFilter(array) {
      this.displays.filter = array
      this.$emit('recepVal',this.displays)
    },

    changeEC(array) {
      this.displays.params.champs = array
      this.$emit('recepVal',this.displays)     
    },

    getFields() {

      this.API({ type: 'post', url: 'engine/module/getfieldswithjunctions', params: {table:this.module.table,filter:this.displays.filter} }).then((resp) => {
          this.Fields = resp.data.fields
                  resp.data.fields.forEach((field) => {
                    this.listFields.push({ 'text': field.table + '.'+field.field, value:field.cle, default:"value" })
                  })
          })

      },

      changeGlobalSearch(item, value, selectedKey) {
      this.displays.params.globalsearch = [];
      for (var selected in value) {
        if (typeof value[selected] == 'object') { this.displays.params.globalsearch.push(value[selected][selectedKey]) } else { this.displays.params.globalsearch.push(value[selected]); }
      }
      this.$emit('recepVal', this.displays)
    },
  
    },
  
    mounted() {
      this.getFields()
    },
  };
  </script>
  
  