<template>
  <div>
    <not-allowed
      v-if="!AllowedDisplay"
      :page="page"
      :module="idmodule"
      :data="datas"
    ></not-allowed>

    <div v-if="AllowedDisplay">
      <div :ref="'chart' + idmodule" style="width: 100%; height: 400px"></div>
    </div>

    <button @click="speak">🔊 Lire</button>

  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: ["idmodule", "page", "modal", "form", "hook"],

  data() {
    return {
      AllowedDisplay: true,
      datas: [],
      chartInstance: null,
      text: "Bonjour, voici une démonstration du text-to-speech avec Vue.js !",
    };
  },

  watch: {
    "datas.general.type": function (newType) {
      if (this.chartInstance) {
        this.chartInstance.setOption(this.getChartOptions(newType));
      }
    },
  },

  methods: {
    speak() {
      if ("speechSynthesis" in window) {
        const utterance = new SpeechSynthesisUtterance(this.text);
        utterance.lang = "fr-FR";
        speechSynthesis.speak(utterance);
      } else {
        alert("La synthèse vocale n'est pas supportée par ce navigateur.");
      }
    },

    callAPI() {
      this.API({
        type: "post",
        url: "displaymodule",
        params: { id: this.idmodule, querys: {}, params: {} },
      }).then((resp) => {
        this.manageDatas(resp.data);
      });
    },

    manageDatas(data) {
      if (!data.success) {
        this.AllowedDisplay = false;
        this.datas = [];
        return;
      }

      this.datas = data;
      this.initCharts();
    },

    initCharts() {
      this.chartInstance = echarts.init(this.$refs["chart" + this.idmodule]);

      if(this.datas.general.type=='test') {
        this.chartInstance.setOption(this.customOpt());
      } else {
        this.chartInstance.setOption(this.getChartOptions(this.datas.general.type));
      }
      
    },

    getChartOptions(type) {
      const commonOptions = {
        title: { text: "Données", left: "center" },
        tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
        legend: {
          top: "5%",
          left: "center",
        },
        grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
      };

      if (!this.datas[type] || !this.datas[type].list) return {};

      const categories = [...new Set(this.datas[type].list.map((d) => d.nom || d.label || "Inconnu"))];

      const seriesMap = {};
      this.datas[type].list.forEach((item) => {
        if (!seriesMap[item.année]) {
          seriesMap[item.année] = [];
        }
        seriesMap[item.année].push(item.valeur || item.total || 0);
      });

      const series = Object.keys(seriesMap).map((year) => ({
        name: year,
        type: "bar",
        data: seriesMap[year],
      }));

      if (type === "bar-horizontal") {
        return {
          ...commonOptions,
          xAxis: { type: "value", boundaryGap: [0, 0.01] },
          yAxis: { type: "category", data: categories },
          series,
        };
      } else if (type === "pie") {
        return {
          ...commonOptions,
          series: [
            {
              name: "Données",
              type: "pie",
              radius: ["0%", "70%"],
              avoidLabelOverlap: false,
              label: { show: true, position: "outside" },
              emphasis: { label: { show: true, fontSize: "16", fontWeight: "bold" } },
              data: this.datas[type].list.map((item) => ({
                name: item.nom || item.label || "Inconnu",
                value: item.valeur || item.total || 0,
              })),
            },
          ],
        };
      } else if (type === "bar") {
        return {
          ...commonOptions,
          xAxis: { type: "category", data: categories },
          yAxis: { type: "value" },
          series,
        };
      } else if (type === "line") {
        return {
          ...commonOptions,
          xAxis: { type: "category", data: categories },
          yAxis: { type: "value" },
          series: series.map((serie) => ({ ...serie, type: "line" })),
        };
      }

      return {};
    },

    customOpt() {
      var option = {
          title: {
              text: 'Suivi Commercial - Performances'
          },
          tooltip: {
              trigger: 'axis'
          },
          legend: {
              data: ['Contacts', 'Rendez-vous', 'Ventes', 'Taux de conversion']
          },
          xAxis: {
              type: 'category',
              data: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin']
          },
          yAxis: [
              {
                  type: 'value',
                  name: 'Quantité'
              },
              {
                  type: 'value',
                  name: 'Taux de conversion (%)',
                  axisLabel: {
                      formatter: '{value} %'
                  }
              }
          ],
          series: [
              {
                  name: 'Contacts',
                  type: 'bar',
                  data: [120, 132, 101, 134, 90, 230],
                  stack: 'Total',
                  itemStyle: { color: '#5470C6' }
              },
              {
                  name: 'Rendez-vous',
                  type: 'bar',
                  data: [60, 72, 80, 102, 50, 150],
                  stack: 'Total',
                  itemStyle: { color: '#91CC75' }
              },
              {
                  name: 'Ventes',
                  type: 'bar',
                  data: [30, 40, 50, 60, 25, 90],
                  stack: 'Total',
                  itemStyle: { color: '#EE6666' }
              },
              {
                  name: 'Taux de conversion',
                  type: 'line',
                  yAxisIndex: 1,
                  data: [25, 30, 35, 40, 20, 45],
                  itemStyle: { color: '#FFAA00' }
              }
          ]
      };

      return option;
    }

  },

  mounted() {
    let getdatas = this.$store.getters.getModuleDatas({
      page: this.page,
      id: this.idmodule,
    });

    if (getdatas) {
      this.manageDatas(getdatas.datas);
      this.$store.dispatch("removeModuleDatas", {
        page: this.page,
        id: this.idmodule,
      });
    } else {
      this.callAPI();
    }
  },
};
</script>
